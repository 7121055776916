import { PageHistoryListComponent } from './page-history-list/page-history-list.component';

import { SharedModule } from './../../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PageHistoryDetailsComponent } from './page-history-details/page-history-details.component';





@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([]),
        SharedModule,
        RouterModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    declarations: [PageHistoryListComponent, PageHistoryDetailsComponent],
    exports: [PageHistoryListComponent, PageHistoryDetailsComponent],
    entryComponents: []
})
export class PageHistoryModule { }
