import { ApiService } from './../../../../../services/api.service';
import { HttpService } from './../../../../../services/http.service';
import { CommonService } from './../../../../../services/common.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-intro-page',
    templateUrl: './intro-page.component.html',
    styleUrls: ['./intro-page.component.scss']
})
export class IntroPageComponent implements OnInit {

    introVideo: any;
    videoUploaded = false;
    subscriptions = {};
    introPageId: any;
    logoUploaded = false;
    logoImage: any;
    constructor(private _cs: CommonService, private httpService: HttpService,
        private modalService: NgbModal,
        private apiService: ApiService) { }

    ngOnInit(): void {
        const self = this;
        self.getIntroPageData();
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'video') {
                self.introVideo = documents.path;
            } else if (documents && documents['type'] === 'logo') {
                self.logoImage = documents.path;
            }
        });
    }

    onVideoUpload(event: any): void {
        this.videoUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'video');
    }
    save() {
        const self = this;
        const payload = {
            video_url: self.introVideo,
            logo : self.logoImage
        };
        if (self.logoImage &&  self.introVideo) {
            self.subscriptions['saveVideo'] = self.httpService.post(self.apiService.getUrl('CMSINTROPAGE'), payload)
                .subscribe(_res => {
                    if (_res && _res['success']) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'Intro page saved successfully!',
                            type: 'success',
                            confirmButtonText: 'Ok'
                        });
                    }
                }, err => {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to save Video!',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
        } else {
            Swal.fire({
                title: 'Warning',
                text: 'PLease Upload both logo and video.',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
        }

    }
    onDeleteintroVideo(event) {
        const self = this;
        this.videoUploaded = event.isFileUploaded;
        self.introVideo = null;
    }

    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    getIntroPageData() {
        const self = this;
        self.httpService.get(self.apiService.getUrl('CMSINTROPAGE')).subscribe(res => {
            if (res && res['success'] && res['page'] && res.page['video_url']) {
                self.introPageId = res.page['_id'];
                self.introVideo = res.page['video_url'];
                self.videoUploaded = true;
            }
        });
    }
    onLogoUpload(event) {
        this.logoUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'logo');
    }
    onDeleteLogo(event) {
        const self = this;
        this.logoUploaded = event.isFileUploaded;
        self.logoImage = null;
    }
}
