import { HistoryModule } from './components/history/history-module';
import { LogosModule } from './components/logos/logos.module';
import { SharedModule } from './../../shared/shared.module';
import { JobPostingModule } from './components/job-posting/job-posting.module';
import { UserModule } from './components/user/user.module';
import { FooterModule } from './../footer/footer.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AdminComponent } from './components/admin.component';
import { AdminMenuComponent } from './components/admin-menu/admin-menu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CompanyprofileComponent } from './components/companyprofile/companyprofile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ADMIN_ROUTES } from './admin.routes';
import { AdminPropertiesModule } from './components/admin-properties/admin-properties.module';
import { AdminEventsModule } from './components/admin-events/admin-events.module';
import { AdminRecommendationsPostingModule } from './components/recommendations/recommendations.module';
import { WebsiteLeadListModule } from './website-leads/website-lead.module';
import { PagesModule } from './components/pages/pages.module';



@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ADMIN_ROUTES),
        FooterModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        UserModule,
        AdminPropertiesModule,
        AdminEventsModule,
        JobPostingModule,
        AdminRecommendationsPostingModule,
        WebsiteLeadListModule,
        PagesModule,
        LogosModule,
        HistoryModule
    ],
    declarations: [AdminComponent, AdminMenuComponent, DashboardComponent, CompanyprofileComponent],
    exports: []
})
export class AdminModule { }
