import { ApiService } from './../../../../services/api.service';
import { HttpService } from './../../../../services/http.service';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Lightbox } from 'ngx-lightbox';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutData: any;
  @ViewChild('about') about: ElementRef;
  customOptions: any = {
     loop: true,
    mouseDrag: true,
    touchDrag: true,
    dragging: true,
    dragClass: 'owl-drag',
    pullDrag: true,
    dots: true,
    navSpeed: 500,
    autoWidth: true,   
    navText: ['&nbsp', '&nbsp'],
  responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      },
      1260: {
        items: 3
      }
    },
    nav: true
  };
  isArabic  = false;
  subscriptions = {};

  constructor(
    private http: HttpService,
    private api: ApiService,
    private _lightbox: Lightbox,
    @Inject(LOCAL_STORAGE) private localStorage: any,
  ) {

  }


  ngOnInit() {
    const self = this;
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.isArabic = true;
      self.getData('ar');
    } else {
      self.getData('en');
      self.isArabic = false;
    }
  }

  moveToGallery() {
    const self = this;
    self.about.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }
  getData(lang) {
    const self = this;
    self.subscriptions['getData'] = self.http.get(self.api.getUrl('WEBABOUTPAGE') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.aboutData = data['content'];
        }
      });
  }
  open(item: Array<any>) {
     const self = this;
     const album = [];
     if (item && item['gallery'] && item['gallery'].length) {
       item['gallery'].forEach(ele => {
         const obj = {
           src: ele,
           caption: '',
           thumb: item['main_image']
         };
         album.push(obj);
       });
     self._lightbox.open(album);
     }
   }
}
