import { Component, ViewEncapsulation, OnInit, Inject, PLATFORM_ID, Compiler } from '@angular/core';
import { LOCAL_STORAGE, WINDOW } from '@ng-toolkit/universal';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    title = 'zaleej-ui';
    isArabic = false;
    constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
        @Inject(PLATFORM_ID) private platform: Object,
        @Inject(WINDOW) private window: Window,
        private router: Router, private compile: Compiler,
) {    

        this.compile.clearCache();

        // if ((<any>window)
        //     && (<any>window).location
        //     && (<any>window).location.protocol
        //     && (<any>window).location.protocol == 'http:') {
        //     window.location.href = "https://zaleej.com";
        // }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (isPlatformBrowser(this.platform)) {
                    if (event.urlAfterRedirects !== 'admin' && (<any>window).ga) {
                        (<any>window).ga('set', 'page', event.urlAfterRedirects);
                        (<any>window).ga('send', 'pageview');
                    }
                }
            }
        });

    }
    ngOnInit(): void {
        const self = this;
        const isArabic = self.localStorage.getItem('isArabic');
        if (isArabic === 'true') {
            self.isArabic = true;
        } else {
            self.isArabic = false;
        }
    }
}
