import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactModule } from './../contact/contact.module';
import { FooterModule } from './../footer/footer.module';
import { PropertiesComponent } from './components/properties/properties.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { LightboxModule } from 'ngx-lightbox';
import { Ng5SliderModule } from 'ng5-slider';



import { PropertyGridComponent } from './components/property-grid/property-grid.component';



@NgModule({
    imports: [CommonModule, RouterModule, FooterModule, FormsModule,
        ReactiveFormsModule, Ng5SliderModule,
        NgxPaginationModule, LightboxModule, ContactModule],
    declarations: [PropertiesComponent, PropertyGridComponent],
    exports: [PropertiesComponent, PropertyGridComponent]
})
export class PropertiesModule { }
