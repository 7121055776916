import { ImageCropperComponent } from './../../../../shared/components/image-croppper/image-cropper.component';
import { ImageCropModule } from './../../../../shared/components/image-croppper/image-cropper.module';
import { SharedModule } from './../../../../shared/shared.module';
import { ListChildRendererComponent } from './../../../../shared/components/list-child';
import { AdminEventsComponent } from './admin-events/admin-events.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AdminEventsListComponent } from './admin-events-list/admin-events-list.component';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
    {
        path: '',
        component: AdminEventsListComponent
    },
    {
        path: 'list',
        component: AdminEventsListComponent
    },
    {
        path: 'new',
        component: AdminEventsComponent
    },
    {
        path: ':eventId',
        component: AdminEventsComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([ListChildRendererComponent]),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        RouterModule,
        NgbModalModule,
        NgbTooltipModule,
        ImageCropModule
    ],
    declarations: [AdminEventsComponent, AdminEventsListComponent, AdminEventsComponent],
    exports: [AdminEventsComponent, AdminEventsListComponent, AdminEventsComponent],
    entryComponents: [ImageCropperComponent]
})
export class AdminEventsModule { }
