import { ApiService } from './../../../../services/api.service';
import { HttpService } from './../../../../services/http.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-companyprofile',
  templateUrl: './companyprofile.component.html',
  styleUrls: ['./companyprofile.component.scss']
})
export class CompanyprofileComponent implements OnInit {

  companyProfileForm: FormGroup;
  subscriptions: any = {};
  selectedCompanyName;
  selectedAddress;
  selectedFooter;
  companyData: any;
  companyId: any;
  constructor(private formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private httpService: HttpService,
    private api: ApiService
  ) {

  }

  ngOnInit() {
    const self = this;
    self.selectedAddress = self.selectedCompanyName = self.selectedFooter = 'english';
    self.buildForm();
    self.getCompanyData();
  }


  buildForm() {
    const self = this;
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    self.companyProfileForm = self.formBuilder.group({
      'companyName': ['', Validators.required],
      'companyNameArabic': ['', Validators.required],
      'address': ['', Validators.required],
      'addressArabic': ['', Validators.required],
      // 'lattitude': ['', Validators.required],
      // 'longitude': ['', Validators.required],
      'phone': ['', Validators.required],
      // 'facebook': ['', Validators.pattern(reg)],
      // 'instagram': ['', Validators.pattern(reg)],
      // 'twitter': ['', Validators.pattern(reg)],
      // 'youtube': ['', Validators.pattern(reg)],
      // 'linkedIn': ['', Validators.pattern(reg)],
      // 'footer': ['', Validators.required],
      'facebook': [''],
      'instagram': [''],
      'twitter': [''],
      'youtube': [''],
      'linkedIn': [''],
      'footer': ['', Validators.required],
      'footerArabic': ['', Validators.required],
      'mapUrl': ['', Validators.required],
      'showIntroVideo': ['', Validators.required]
    });
  }

  save() {
    const self = this;
    const payload = self.generatePayload(self.companyProfileForm.getRawValue());
    if (self.companyId) {
      self.update(self.companyId, payload);
    } else {
      self.create(payload);
    }
  }
  create(payload) {
    const self = this;
    self.subscriptions['companyProfileData'] = self.httpService.put(self.api.getUrl('COMPANY'), payload).subscribe(_res => {
      if (_res && _res['success']) {
        Swal.fire({
          title: 'Success!',
          text: 'Company Profile created Successfully!',
          type: 'success',
          confirmButtonText: 'Ok'
        });
      }
    }, err => {
      Swal.fire({
        title: 'Error!',
        text: 'Unable to save Data',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  update(id, payload) {
    const self = this;
    self.subscriptions['companyProfileData'] = self.httpService.post(self.api.getUrl('COMPANY'), payload).subscribe(_res => {
      if (_res && _res['success']) {
        Swal.fire({
          title: 'Success!',
          text: 'Company Profile Updated Successfully!',
          type: 'success',
          confirmButtonText: 'Ok'
        });
      }
    }, err => {
      Swal.fire({
        title: 'Error!',
        text: 'Unable to update Data',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }
  generatePayload(formdata) {
    const self = this;
    const payload = {
      'name_en': formdata['companyName'],
      'name_ar': formdata['companyNameArabic'],
      'address_en': formdata['address'],
      'address_ar': formdata['addressArabic'],
      // 'latitude': formdata['lattitude'],
      // 'longitude': formdata['longitude'],
      'phone': formdata['phone'],
      'copyright_en': formdata['footer'],
      'copyright_ar': formdata['footerArabic'],
      'facebook_link': formdata['facebook'],
      'twitter_link': formdata['twitter'],
      'instagram_link': formdata['instagram'],
      'linkendin_link': formdata['linkedIn'],
      'youtube_link': formdata['youtube'],
      'map_url': formdata['mapUrl'],
      'showIntroVideo': formdata['showIntroVideo']
    };
    if (self.companyId) {
      payload['_id'] = self.companyId;
  }
    return payload;
  }
  getfield(controlName: string) {
    const controls = controlName.split('.');
    let currentCtrl = this.companyProfileForm.controls[controls[0]];
    controls.forEach((ctrl, i) => {
      if (i !== 0) {
        currentCtrl = currentCtrl['controls'][ctrl];
      }
    });
    return currentCtrl;
  }

  reset() {
    const self = this;
    self.companyProfileForm.reset();
  }
  selectTabCompanyName(tab) {
    const self = this;
    self.selectedCompanyName = tab;
  }
  selectAddressTab(tab) {
    const self = this;
    self.selectedAddress = tab;
  }
  selectTabFooter(tab) {
    const self = this;
    self.selectedFooter = tab;
  }
  redirect(link, event) {
    if (link && link['value'] && window) {
      window.open(link['value'], '_black');
    }
  }
  getCompanyData() {
    const self = this;
    self.httpService.get(self.api.getUrl('COMPANY')).subscribe(res => {
      if (res && res['success'] && res['company']) {
        self.companyData = res;
        self.companyId = res.company['_id'],
          self.setFormData(res);
      }
    });
  }
  setFormData(formdata) {
    const self = this;
    self.companyProfileForm.patchValue({
      'companyName': formdata.company['name_en'],
      'companyNameArabic': formdata.company['name_ar'],
      'address': formdata.company['address_en'],
      'addressArabic': formdata.company['address_ar'],
      // 'lattitude': formdata.company['latitude'],
      // 'longitude': formdata.company['longitude'],
      'phone': formdata.company['phone'],
      'footer': formdata.company['copyright_en'],
      'footerArabic': formdata.company['copyright_ar'],
      'facebook': formdata.company['facebook_link'],
      'twitter': formdata.company['twitter_link'],
      'instagram': formdata.company['instagram_link'],
      'linkedIn': formdata.company['linkendin_link'],
      'youtube': formdata.company['youtube_link'],
      'mapUrl': formdata.company['map_url'],
      'showIntroVideo': formdata.company['showIntroVideo']
    });
    self.companyId = formdata.company['_id'];
  }
}
