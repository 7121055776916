import { ImageCropModule } from './../../../../shared/components/image-croppper/image-cropper.module';
import { SharedModule } from './../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LogosComponent } from './logos.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        NgbTooltipModule,
        ImageCropModule,
        RouterModule,
    ],
    declarations: [LogosComponent],
    exports: [LogosComponent],
    entryComponents: []
})
export class LogosModule { }
