import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { InputErrorComponent } from './components/input-error/input-error.component';
import { PaginateButtonDirective } from './directives/paginate-button.directive';
import { PaginatePipe } from './pipes/paginate.pipe';
import { ListChildRendererComponent } from './components/list-child';
import { WebsiteLeadChildRendererComponent } from './components/website-lead-child';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ImageCropperModule } from 'ngx-image-cropper';




@NgModule({
     imports: [CommonModule, NgbTooltipModule, NgbModalModule, ImageCropperModule],
    exports: [InputErrorComponent, FileUploadComponent, PaginatePipe, PaginateButtonDirective,
         ListChildRendererComponent, WebsiteLeadChildRendererComponent, CheckboxComponent, TabsComponent],
    declarations: [InputErrorComponent, FileUploadComponent, PaginatePipe, PaginateButtonDirective,
         ListChildRendererComponent, WebsiteLeadChildRendererComponent, CheckboxComponent, TabsComponent],
})
export class SharedModule {}
