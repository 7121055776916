import { Component, DoCheck, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-input-error,[app-input-error]',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent implements DoCheck {
    errorMsg: string;
    @Input() isArabic = false;
    @Input() control: FormControl;

    constructor() {}

    ngDoCheck() {
        const errors = this.control.errors;
        if (errors) {
            let errorKeys: any;
            let currentKey: any;
            if (Array.isArray(errors)) {
                errorKeys = errors;
                currentKey = Object.keys(errorKeys[0])[0];
            } else {
                errorKeys = Object.keys(errors);
                currentKey = errorKeys[0];
            }
            switch (currentKey) {
                case 'required':
                    this.errorMsg = this.isArabic ? 'هذا الحقل إلزامي' : 'This field is Mandatory';
                    break;
                case 'minlength':
                    this.errorMsg = `Required min length of ${this.control.getError('minlength').requiredLength}`;
                    break;
                case 'maxlength':
                    this.errorMsg = `Exceeds max length ${this.control.getError('maxlength').requiredLength}`;
                    break;
                case 'max':
                    this.errorMsg = `Exceeds max length`;
                    break;
                case 'min':
                    this.errorMsg = `Required min length`;
                    break;
                case 'email':
                    this.errorMsg = `Invalid email address`;
                    break;
                case 'pattern':
                    this.errorMsg = `Invalid URL`;
                    break;
                case 'notEquivalent':
                    this.errorMsg = 'Passwords does not match';
                    break;
            }
        }
    }
}
