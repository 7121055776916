import { VideoGuard } from './services/video-guard.service';
import { ADMIN_ROUTES } from './modules/admin/admin.routes';
import { AdminComponent } from './modules/admin/components/admin.component';
import { PropertiesComponent } from './modules/properties/components/properties/properties.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProtectedComponent } from './modules/protected/protected.component';
import { HomeComponent } from './modules/home/components/home/home.component';
import { EntryComponent } from './modules/entry/entry.component';
import { AboutUsComponent } from './modules/about us/components/about-us/about-us.component';
import { ContactComponent } from './modules/contact/components/contact/contact.component';
import { CareersComponent } from './modules/careers/components/careers/careers.component';
import { TestimonialComponent } from './modules/testimonial/components/testimonial/testimonial.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthGuardService } from './services/auth-guard.service';


const routes: Routes = [
    {
        path : 'login',
        component: LoginComponent
    },
    {
        path: '',
        component: EntryComponent,
        data: {
            animate: true,
        },
        canActivate: [VideoGuard],
    },
    {
        path: 'admin',
        component: AdminComponent,
         canActivate: [AuthGuardService],
        children: ADMIN_ROUTES
    },
    {
        path: '',
        component: ProtectedComponent,
        children: [
            {
                path: 'home',
                component: HomeComponent,
                data: {
                    animate: true,
                },
            },
            {
                path: 'properties',
                component: PropertiesComponent,
                data: {
                    animate: true,
                },
            },
            {
                path: 'about',
                component: AboutUsComponent,
                data: {
                    animate: true,
                },
            },
            {
                path: 'contact',
                component: ContactComponent,
                data: {
                    animate: true,
                },
            },
            {
                path: 'careers',
                component: CareersComponent,
                data: {
                    animate: true,
                },
            },
            {
                path: 'testimonial',
                component: TestimonialComponent,
                data: {
                    animate: true,
                },
            },
            {
                path: '',
                redirectTo: 'home',
                pathMatch: 'full',
                data: {
                    animate: true,
                },
            },
            {
                path: '**',
                redirectTo: '/home'
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
