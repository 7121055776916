import { AuthService } from './../../services/auth.service';
import { ApiService } from './../../services/api.service';
import { HttpService } from './../../services/http.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from './services/login.service';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginError = 'Username and Password is Required';
  @ViewChild('loginForm') loginForm: FormGroup;
  user: any = {
    username: '',
    password: ''
  };
  validateForm = false;
  constructor(private router: Router,
    private http: HttpService,
    private api: ApiService,
    private el: ElementRef,
    private _loginService: LoginService,
    private _auth: AuthService) { }

  ngOnInit() {
  }

  login(formData) {
    const self = this;
    if (self.loginForm.valid) {
      this._loginService.login(formData).subscribe(
        data => {
          if (data && data['success']) {
            this._auth.setToken(data['token']);

            this._auth.setUser(formData.username);
            this.router.navigate(['/admin/dashboard']);
          } else {
            Swal.fire({
              title: 'Error!',
              text: data['message'],
              type: 'error',
              confirmButtonText: 'Ok'
            });
          }
        },
        err => {
          self.validateForm = true;
          if (err.status === 401) {
            self.loginForm.controls['password'].patchValue('');
            self.loginForm.controls['password'].markAsTouched();
            self.loginForm.controls['password'].setErrors({ 'incorrect': true });
            self.loginForm.controls['username'].patchValue('');
            self.loginForm.controls['username'].markAsTouched();
            self.loginForm.controls['username'].setErrors({ 'incorrect': true });
            const formGroupInvalid = this.el.nativeElement.querySelectorAll('.ng-invalid');
            (<HTMLInputElement>formGroupInvalid[0]).focus();
          }
        }
      );
    } else {
      self.validateForm = true;
    }
  }

}
