import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true
};

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [CHECKBOX_VALUE_ACCESSOR]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

  @ViewChild('inputEl') inputEl: ElementRef;

  @Input() value: any;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() id: string;
  @Input() emitEvent = false;
  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() checked = false;
  checkBoxId: string;
  onModelChange: Function = () => {
  }

  onModelTouched: Function = () => {
  }

  writeValue(model: any): void {
    const self = this;
    self.checked = model;
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  constructor() {
  }

  ngOnInit() {
    if (this.id) {
      this.checkBoxId = this.id || 'chk-generic-checkbox-checkbox-v1';
    }
  }

  onChange($event) {
    const self = this;
    $event.preventDefault();
    $event.stopPropagation();
    self.onModelChange($event.target.checked);
    self.change.emit({
      originalEvent: $event,
      value: $event.target.checked
    });
  }

  setCheckedState(state) {
    const self = this;
    self.inputEl.nativeElement.checked = state;
  }
}
