import { Subject } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-property-grid',
  templateUrl: './property-grid.component.html',
  styleUrls: ['./property-grid.component.scss']
})
export class PropertyGridComponent implements OnInit {

 @Input() properties: any;
  @Input() isArabic = false;
  @Input() filterProperties$: Subject<any> = new Subject();
  @Input() isLoading = false;

  constructor(private _lightbox: Lightbox) { }

  ngOnInit() {
    const self = this;
   }
   open(item: Array<any>) {
     const self = this;
     const album = [];
     if (item && item['gallery'] && item['gallery'].length) {
       item['gallery'].forEach(ele => {
         const obj = {
           src: ele,
           caption: '',
           thumb: item['main_image']
         };
         album.push(obj);
       });
     self._lightbox.open(album);
     }
   }
   getImage(image: String) {
     if (image.includes('/apidata')) {
       image =  image.replace('/apidata', 'data');
       return image;
     } else {
       return image;
     }
   }

}
