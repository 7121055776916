import { ImageCropperComponent } from './../../../../../shared/components/image-croppper/image-cropper.component';
import { CommonService } from './../../../../../services/common.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-career-page',
    templateUrl: './career-page.component.html',
    styleUrls: ['./career-page.component.scss']
})
export class CareerPageComponent implements OnInit {
    pageForm: FormGroup;
    videosUploadUrl = 'VIDEO_URL';
    slideUploadUrl = 'SLIDE_URL';
    bannerUploadUrl = 'BANNER_URL';
    subscriptions = {};
    selectedBannerTitle: any;
    selectedBannerSubTitle: any;
    selectedDescription: any;
    isBannerUploaded = false;
    bannerImage: any;
    careerPageId: any;
    careerPageData: any;

    constructor(
        private fb: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private _cs: CommonService,
        private modalService: NgbModal,
    ) { }
    ngOnInit(): void {
        const self = this;
        self.selectedBannerTitle = self.selectedBannerSubTitle = self.selectedDescription = 'english';
        self.buildForm();
        self.getPageData();
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'bannerImage') {
                self.bannerImage = documents.path;
            }
        });
    }

    buildForm() {
        const self = this;
        self.pageForm = self.fb.group({
            banner_caption_en: ['', Validators.required],
            banner_caption_ar: ['', Validators.required],
        });
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.pageForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }

    onDeleteFile() {
        const self = this;
    }
    reset() {
        const self = this;
        self.pageForm.reset();
    }

    save() {
        const self = this;
        const payload = self.generatePayload(self.pageForm.getRawValue());
        self.subscriptions['saveHomeData'] = self.httpService.post(self.api.getUrl('CMSCAREERPAGE'), payload)
            .subscribe(_res => {
                if (_res && _res['success']) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Career Page data created successfully!',
                        type: 'success',
                        confirmButtonText: 'Ok'
                    }).then((isConfirm) => {
                        if (isConfirm) {
                            self._router.navigateByUrl('admin/pages/career');
                        }
                    });
                }
            }, err => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Career Page data creation failed!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    }
    selectTabBannerTitle(tab) {
        const self = this;
        self.selectedBannerTitle = tab;
    }
    selectTabBannerSubTitle(tab) {
        const self = this;
        self.selectedBannerSubTitle = tab;
    }
    selectTabDescription(tab) {
        const self = this;
        self.selectedDescription = tab;
    }
    onImageUpload(event: any): void {
        this.isBannerUploaded = event.isFileUploaded;
        // this.fileUploadSuccess = true;
        this._cs.addDocument(event.response.file, 'banner');
    }
    generatePayload(formData) {
        const self = this;
        const payload = {
            banner_caption_en: formData['banner_caption_en'],
            banner_caption_ar: formData['banner_caption_ar'],
        };
        if (self.careerPageId) {
            payload['_id'] = self.careerPageId;
        }
        if (self.bannerImage) {
            payload['banner_image'] = self.bannerImage;
        }

        return payload;
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    onDeleteBannerImage(event) {
        const self = this;
        this.isBannerUploaded = event.isFileUploaded;
        self.bannerImage = null;
    }
    getPageData() {
        const self = this;
        self.httpService.get(self.api.getUrl('CMSCAREERPAGE')).subscribe(res => {
            if (res && res['success'] && res['page']) {
                self.careerPageData = res['page'];
                self.careerPageId = res.page['_id'],
                    self.setFormData(res['page']);
            }
        });
    }
    setFormData(formData) {
        const self = this;
        if (formData) {
            self.bannerImage = formData['banner_image'];
            self.isBannerUploaded = true;
            self.pageForm.patchValue({
                banner_caption_en: formData['banner_caption_en'],
                banner_caption_ar: formData['banner_caption_ar'],
            });
        }
    }
    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.aspectRatio = 5 / 4;
        modalRef.componentInstance.resizeToWidth = 1920;
        modalRef.componentInstance.imageQuality = 40;
        //  modalRef.componentInstance.maintainAspectRatio = true;
        // modalRef.componentInstance.resizeToHeight = 1080;
        modalRef.componentInstance.format = 'jpeg';
    }

}
