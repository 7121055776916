import { ApiService } from './../../../../../services/api.service';
import { ListChildRendererComponent } from './../../../../../shared/components/list-child';
import { HttpService } from './../../../../../services/http.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-events-list',
  templateUrl: './admin-events-list.component.html',
  styleUrls: ['./admin-events-list.component.scss']
})
export class AdminEventsListComponent implements OnInit, OnDestroy {
  eventsForm: FormGroup;
  apiEndPoint: string;
  filterData: any;
  gridOptions: any;
  destroyChild = false;
  pagination: any;
  swapComponent = false;
  totalRowData: any;
  displayRequired = false;
  params: any;
  searchValue: any;
  @ViewChild('inputSearch') inputSearch: ElementRef;

  private paginationPageSize = 20;
  pageNo = 1;
  filter: any;
  stopCall = true;
  totalSize: number;
  private gridApi;
  private gridColumnApi;
  private rowData: any[];
  private frameworkComponents;
  private context;
  columnDefs;
  private components;
  private getRowNodeId;
  subscriptions: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private httpService: HttpService,
    private api: ApiService
  ) {
    this.getRowNodeId = function (data) {
      return data.code;
    };

    this.columnDefs = [
      {
        headerName: 'SI. No',
        width: 150,
        valueGetter: 'node.id',
        cellRenderer: 'rowIdRenderer'
      },
      {
        headerName: 'Event Name',
        field: 'name_en',
        minWidth: 300
      },
      {
        headerName: 'Event Venue',
        field: 'venue_en',
        minWidth: 200
      },
      {
        headerName: 'Event Country',
        field: 'country_en',
        minWidth: 200
      },
      {
        headerName: 'Manage',
        cellRenderer: 'eventListUpdate',
        suppressMenu: true,
        suppressFilter: true,
      }
    ];
    this.context = { componentParent: this };
    this.frameworkComponents = {
      eventListUpdate: ListChildRendererComponent
    };
    this.components = {
      rowIdRenderer: function (params) {
        return Number(params.rowIndex) + 1;
      },
    };
  }

  public ngOnInit() {
    const self = this;
    if (this.columnDefs) {
      this.configureGridSettings();
    }
    self.buildEventsForm();
  }

  buildEventsForm() {
    const self = this;
    self.eventsForm = self.formBuilder.group({
      'name': ['', []],
      'userName': ['', []],
    });

  }

  configureGridSettings(): void {
    this.gridOptions = {
      frameworkComponents: this.frameworkComponents,
      enableFilter: false,
      components: this.components,
      context: this.context,
      enableSorting: false,
      enableColResize: true,
      rowDeselection: false,
      rowHeight: 45,
      pagination: true,
      columnDefs: this.columnDefs,
      headerHeight: 45,
    };
  }

  onReady(params: any): any {
    const self = this;
    self.gridApi = params.api;
    self.params = params;
    self.gridColumnApi = params.columnApi;
    self.httpService.get(self.api.getUrl('EVENTS'))
      .subscribe(data => {
        params.api.setRowData(data['events']);
      });
  }
  ngOnDestroy() {
    const self = this;
    Object.keys(self.subscriptions).forEach(e => {
      self.subscriptions[e].unsubscribe();
    });
  }


  edit(data) {
    const self = this;
    self._router.navigateByUrl('admin/events/' + data['_id']);
  }

  delete(data) {
    const self = this;
    Swal.fire({
      title: 'Warning!',
      text: 'Event will be Deleted.',
      type: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then(isConfirm => {
      if (isConfirm && isConfirm['value']) {
        self.httpService.delete(self.api.getUrl('EVENTS') + '/' + data['_id'])
          .subscribe((_res) => {
             this.params.filterModel = {};
            this.onReady(this.params);
          }, err => {
            Swal.fire({
              title: 'Error!',
              text: 'Unable to Delete Event',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          });
      }
    });
  }
  navigate() {
    const self = this;
    self._router.navigateByUrl('admin/events/new');
  }
  onSearchChange(input) {
    const self = this;
    this.gridApi.setQuickFilter(self.searchValue);
  }
}
