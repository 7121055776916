import { WINDOW } from '@ng-toolkit/universal';
import { Component, EventEmitter, Input, Output, ViewEncapsulation , Inject} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../../services/api.service';
import { HttpService } from '../../../services/http.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FileUploadComponent {
    @Input() id: string;
    @Input() uploadType = 'image';
    @Input() API_PATH: string;
    @Input() deleteFile = true;
    @Input() disabled = false;
    @Input() preview = false;
    @Input() multiple = false;
    @Input() accept = '*';
    @Input()
    set getFile(doc: any) {
        if (!!doc) {
            this.downloadFile(doc.filename);
        }
    }

    @Output() fileDownloaded = new EventEmitter();
    @Output() fileUploaded = new EventEmitter();
    @Output() removeFile = new EventEmitter();

    @Input() isFileUploaded = false;
    @Input() type = 'File';
    @Input() imageUploadType;
    errorMsgs: Array<any> = [];
    fileName: string;
    previewUrl: string;


    // tslint:disable-next-line:max-line-length
    constructor(@Inject(WINDOW) private window: Window, private http: HttpService, private api: ApiService, private modalService: NgbModal) {}

    onFileChange(event) {
        const self = this;
        const reader = new FileReader();
        let height, width;
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            this.fileName = file.name;
            const image = new Image();
            reader.readAsDataURL(file);
            reader.onload = (e: any) => {
                self.errorMsgs = [];
                if (self.imageUploadType === 'video') {
                    if (file && file.size > 50000000) {
                        self.errorMsgs.push('Video Size should be less than 50MB');
                    }
                    if (self.errorMsgs.length === 0) {
                        self.previewUrl = e.target.result;
                        self.uploadFile(file);
                    }
                } else {
                    image.src = e.target.result;
                    image.onload = function () {
                        height = image.height;
                        width = image.width;
                        if ((height > 85 && width > 85) && self.imageUploadType === 'avatar') {
                            self.errorMsgs.push('Width and height should 85 X 85');
                        }
                        // if (height < 100 || width < 200) {
                        //     self.errorMsgs.push('Width and height minimum 200 X 100');
                        // }
                        if (file.size > 1000000 && self.imageUploadType !== 'video') {
                            self.errorMsgs.push('File size should be less than 1MB');
                        }
                        if (self.errorMsgs.length === 0) {
                            self.previewUrl = e.target.result;
                            self.uploadFile(file);
                        }
                    };
                }
            };
        }
    }

    uploadFile(file: File) {
        if (this.API_PATH) {
            const formData: FormData = new FormData();
            formData.append(this.uploadType, file);
            this.http.postFormData(this.api.getUrl(this.API_PATH), formData).subscribe(
                data => {
                    this.isFileUploaded = true;
                    Swal.fire({
                        title: 'Success!',
                        text: 'File uploaded successfully!',
                        type: 'success',
                        confirmButtonText: 'Ok'
                    }).then((isConfirm) => {
                    });
                    this.fileUploaded.emit({
                        previewUrl: this.previewUrl,
                        file,
                        response: data,
                        isFileUploaded : true,
                    });
                },
                err => {
                    Swal.fire({
                        title: 'Failed!',
                        text: 'File uploaded failed!',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    }).then((isConfirm) => {
                    });
                }
            );
        } else {
            this.fileUploaded.emit({
              previewUrl: this.previewUrl,
              file: file,
              response: null
            });
        }
    }

    downloadFile(fileName: string) {
        this.http.downloadData(this.api.getUrl('FILE_DOWNLOAD_PATH') + '/' + fileName).subscribe(
            data => {
                const blob = new Blob([data], { type: 'image/jpeg' });
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = (e: any) => {
                    // this.previewUrl = window.URL.createObjectURL(data);
                    this.previewUrl = e.target.result;
                    this.isFileUploaded = true;
                    this.fileDownloaded.emit({
                        previewUrl: this.previewUrl,
                    });
                };
            },
            err => {
                console.log('download data error', err);
            }
        );
    }

    onFileRemove() {
        this.isFileUploaded = false;
        this.removeFile.emit({
            isFileUploaded: false
        });
    }

    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
}
