import { filter } from 'rxjs/operators';
import { ApiService } from './../../../../services/api.service';
import { HttpService } from './../../../../services/http.service';
import { CommonService } from './../../../../services/common.service';
import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import * as _ from 'lodash';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Options } from 'ng5-slider';



@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {

  @ViewChild('propertiesGrid') propertiesGrid: ElementRef;
  backGroundImage: any = 'assets/images/properties.webp';
  isLoading = true;
  @ViewChild('selectBox') selectBox: ElementRef;
  properties: any;
  selectedFilterEn = 'all';
  typeFilterEn: Array<any> = [
    { 'label': 'all', 'value': 'all' },
    { 'label': 'commercial', 'value': 'COMMERCIAL' },
    { 'label': 'industrial', 'value': 'INDUSTRIAL' },
    { 'label': 'residential', 'value': 'RESIDENTIAL' }
  ];
  typeFilterAr: Array<any> = [
    { 'label': 'الكل', 'value': 'all' },
    { 'label': 'تجاري', 'value': 'COMMERCIAL' },
    { 'label': 'صناعي', 'value': 'INDUSTRIAL' },
    { 'label': 'سكني', 'value': 'RESIDENTIAL' }
  ];
  selectLocations: any;
  searchForm: FormGroup;
  filterProperties$: Subject<any> = new Subject();
  isArabic = false;
  subscriptions = {};
  filter = {};
  location: any;
  filterProperty = false;

  roomValue: any;
  roomOptions: Options = {
    floor: 1,
    ceil: 5
  };
  sizeValue: any;
  sizes = [
    {min: 0, max: 50},
    { min: 50, max: 100 },
    { min: 100, max: 150 },
    { min: 150, max: 200 },
    { min: 200, max: 400 },
    { min: 400, max: 600 },
    { min: 600, max: 800 },
    { min: 800, max: 1000 },
    { min: 1000, max: 1500 },
    {min: 1500, max: 9999}
  ];
  sizeOptions: Options = {
    floor: 1,
    ceil: 5000
  };

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
    private http: HttpService,
    private api: ApiService,
    private fb: FormBuilder, ) { }


  ngOnInit() {
    const self = this;
    self.buildForm();
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.isArabic = true;
      self.getData('ar');
      self.filter = {
        'lang': 'ar',
        'filters': []
      };
    } else {
      self.isArabic = false;
      self.getData('en');
      self.filter = {
        'lang': 'en',
        'filters': []
      };
    }
  }


  public moveTopropertiesGrid(): void {
    this.propertiesGrid.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
  }
  getData(lang) {
    const self = this;
    self.subscriptions['getHomeData'] = self.http.get(self.api.getUrl('WEBPROPERTYPAGE') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.properties = data['content'];
          self.isLoading = false;
          self.location = _.uniq(self.properties['properties'].map(item => item['location']));
        }
      });
  }
  filterProperties(uiFilter) {
    const self = this;
    self.isLoading = true;
    self.filterProperty = true;
    if (self.properties && self.properties['properties'] && self.properties['properties'].length) {
      self.properties['properties'] = [];
    }
    self.filter['filters'] = [];
    if (uiFilter === 'all') {
      self.selectedFilterEn = uiFilter;
      self.selectLocations = undefined;
      self.roomValue = undefined;
      self.sizeValue = undefined;
      self.searchForm.reset();
      self.getData(self.isArabic ? 'ar' : 'en');
    } else if (uiFilter === 'COMMERCIAL' || uiFilter === 'INDUSTRIAL' || uiFilter === 'RESIDENTIAL') {
      const type = {
        'key_name': 'type',
        'text': uiFilter
      };
      self.filter['filters'].push(type);
      self.selectedFilterEn = uiFilter;
      self.getFilteredProperties();
    }
  }
  onLocationChange(val) {
    const self = this;
    self.isLoading = true;
    self.filterProperty = true;
    self.filter['filters'] = self.filter['filters'].filter(item => item['key_name'] !== 'location');
    const location = {
      'key_name': 'location',
      'text': self.selectLocations
    };
    self.filter['filters'].push(location);
    self.getFilteredProperties();

  }

  getFilteredProperties() {
    const self = this;
    if (self.filterProperty) {
      self.subscriptions['filterProperties'] = self.http.post(self.api.getUrl('WEBPROPERTYPAGEFILTER'), self.filter)
        .subscribe(data => {
          if (data && data['success']) {
            self.isLoading = false;
            self.properties['properties'] = data['properties'];
          }
        });

    }
  }
  buildForm() {
    const self = this;
    self.searchForm = self.fb.group({
      'rooms': ['', [Validators.minLength(1), Validators.maxLength(1)]],
        'size': ['', [Validators.minLength(1), Validators.maxLength(6)]],
    });
  }
  onSizeSelect(val) {
    const self = this;
    if (self.sizeValue) {
      self.filterProperty = true;
      self.isLoading = true;
      self.filter['filters'] = self.filter['filters'].filter(item => item['key_name'] !== 'size');
      const location = {
        'key_name': 'size',
        'min_value': self.sizeValue['min'],
        'max_value': self.sizeValue['max']
      };
      self.filter['filters'].push(location);
      self.getFilteredProperties();

    }
  }
  onRoomSelect(val) {
    const self = this;
    if (Number(self.roomValue) > 0) {
      self.filterProperty = true;
      self.isLoading = true;
      self.filter['filters'] = self.filter['filters'].filter(item => item['key_name'] !== 'rooms');
      const location = {
        'key_name': 'rooms',
        'min_value': 1,
        'max_value': Number(self.roomValue)
      };
      self.filter['filters'].push(location);
      self.getFilteredProperties();
    }
  }
}


