import { CommonService } from './../../../../../../services/common.service';
import { WebsiteLeadChildRendererComponent } from './../../../../../../shared/components/website-lead-child';
import { ApiService } from './../../../../../../services/api.service';
import { HttpService } from './../../../../../../services/http.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-property-history-view',
    templateUrl: './property-history-view.component.html',
    styleUrls: ['./property-history-view.component.scss']
})
export class PropertyHistoryViewComponent implements OnInit , OnDestroy {
    apiEndPoint: string;
    gridOptions: any;
    pagination: any;
    totalRowData: any;
    params: any;
    searchValue: any;
    dataSource: any;
    private gridColumnApi;

    @ViewChild('inputSearch') inputSearch: ElementRef;

    pageNo = 1;
    filter: any;
    stopCall = true;
    totalSize: number;
    private gridApi;
    private frameworkComponents;
    private context;
    columnDefs;
    private components;
    subscriptions: any = {};
    propertyId: any;

    constructor(

        private _router: Router,
        private _route: ActivatedRoute,
        private httpService: HttpService,
        private api: ApiService,
        private _cs: CommonService
    ) {

        this.columnDefs = [
            {
                headerName: 'SI. No',
                width: 150,
                valueGetter: 'node.id',
                cellRenderer: 'rowIdRenderer'
            },
            {
                headerName: 'type',
                field: 'action',
                minWidth: 300
            },
            {
                headerName: 'Date-Time',
                field: 'timestamp',
                minWidth: 200,
                valueFormatter: (params) => this._cs.defaultDateFormat(params, 'DD-MMM-YYYY hh:mm:ss A')
            },
            {
                headerName: 'Username',
                field: 'username',
                minWidth: 200
            },
            {
                headerName: 'Manage',
                cellRenderer: 'leadsListUpdate',
                suppressMenu: true,
                suppressFilter: true
            }
        ];
        this.context = { componentParent: this };
        this.frameworkComponents = {
            leadsListUpdate: WebsiteLeadChildRendererComponent
        };
        this.components = {
            rowIdRenderer: function (params) {
                return Number(params.rowIndex) + 1;
            },
        };
    }

    public ngOnInit() {
        const self = this;
        self.subscriptions['propertyId'] = self._route.params.subscribe(params => {
            if (params && params['propertyId']) {
                self.propertyId = params['propertyId'];
            }
        });
        if (this.columnDefs) {
            this.configureGridSettings();
        }
    }



    configureGridSettings(): void {
        this.gridOptions = {
            frameworkComponents: this.frameworkComponents,
            enableFilter: false,
            components: this.components,
            context: this.context,
            enableSorting: false,
            enableColResize: true,
            rowDeselection: false,
            rowHeight: 45,
            pagination: true,
            columnDefs: this.columnDefs,
            headerHeight: 45,
        };
    }

    onReady(params: any): any {
        const self = this;
        self.gridApi = params.api;
        self.params = params;
        self.gridColumnApi = params.columnApi;
        if (self.propertyId) {
            self.httpService.get(self.api.getUrl('PROPERTIES_HISTORY') + '/' + self.propertyId)
                .subscribe(data => {
                    if (data && data['success']) {
                        params.api.setRowData(data['history']);
                    }
                });
        }
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }

    view(data) {
        const self = this;
        self._router.navigate(['admin/history/property/' + self.propertyId + '/' + 'details' + '/' + data['_id']]);
    }

    onSearchChange(input) {
        const self = this;
        this.gridApi.setQuickFilter(self.searchValue);
    }
    goBack(path) {
        const self = this;
        self._router.navigateByUrl(path);
    }
}
