
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-website-list-child',
    template: `
    <div class="grid-cell">
    <a (click)="view(params);" style="color:blue" class="pointer fa fa-eye pr-2"></a>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <a (click)="delete(params)" *ngIf="showDelete" style="color:red" class="pointer fa fa-trash"></a>
    </div>
    `,
    styles: [
        `
        a:hover {
            cursor: pointer;
        }
         .pointer{
              cursor: pointer;
        }
     `
    ]
})
export class WebsiteLeadChildRendererComponent implements ICellRendererAngularComp {
    public params: any;
    showDelete = true;
    agInit(params: any): void {
        this.params = params;
        if (this.params && (this.params['data']['history'] || this.params['data']['action']) ) {
            this.showDelete = false;
        }
    }
    public view(params) {
        this.params.context.componentParent.view(params['data']);
    }

    public delete(params) {
        this.params.context.componentParent.delete(params['data']);
    }

    refresh(): boolean {
        return false;
    }
}
