import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterModule } from './../footer/footer.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CareersComponent } from './components/careers/careers.component';




@NgModule({
    imports: [CommonModule, RouterModule, FooterModule, ReactiveFormsModule, FormsModule],
    declarations: [CareersComponent],
    providers: [
        NgbActiveModal,
    ]
})
export class CareerstModule { }
