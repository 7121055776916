import { ApiService } from './../../../../services/api.service';
import { HttpService } from './../../../../services/http.service';
import { CommonService } from './../../../../services/common.service';
import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal';
import { DataService } from './../../../../services/data.service';

import { Component, OnInit, HostListener, Input, OnDestroy, Inject, Renderer2, AfterViewInit, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith, debounceTime, map } from 'rxjs/operators';
import * as $ from 'jquery';
import { sticky_header } from '../../../../shared/js/stickyHeader';
import { navMenu, dropdown, sideHeader, animation } from '../../../../shared/js/navMenu';
import { Router, Event, NavigationStart, NavigationEnd } from '@angular/router';
import { trigger, transition, style, animate, state } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import 'rxjs/add/observable/fromEvent';




@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('void', style({ transform: 'translate3d(0, 25%, 0) scale(0.9)', opacity: 0 })),
      state('exit', style({ transform: 'translate3d(0, 25%, 0)', opacity: 0 })),
      transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ])
  ],
})
export class NavComponent implements OnInit, OnDestroy, AfterViewInit {

  ifSticky: boolean;
  mobile: boolean;
  isScreenSmall$: Observable<boolean>;
  showMenuButton: boolean;
  @Input() hidden;
  active: any = 'home';
  navData: any;
  isArabic = false;
  subscriptions = {};
  commonData: any;

  constructor(@Inject(WINDOW) private window: Window,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private router: Router,
    private dataService: DataService,
    private renderer: Renderer2,
    private http: HttpService,
    private api: ApiService,
    @Inject(PLATFORM_ID) private platform: Object,

    private _commonService: CommonService) {

    if (isPlatformBrowser) {
      sticky_header();
      sideHeader();
      dropdown();
    }


    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart || routerEvent instanceof NavigationEnd) {
        sticky_header();
      }
    });
  }


  ngOnInit() {
    const self = this;
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.renderer.addClass(document.body, 'markazi-font');
      self.navData = self.dataService.commonDataArabic();
      self.getcommonData('ar');
      self.isArabic = true;
    } else {
      self.renderer.removeClass(document.body, 'markazi-font');
      self.navData = self.dataService.commonData();
      self.getcommonData('en');
      self.isArabic = false;
    }

  }
  showMenu() {
    const self = this;
    if (self.isScreenSmall$) {
      self.mobile = !self.mobile;
    }
  }
  callNav(_path) {
    const self = this;
    // if (self.isScreenSmall$) {
    //   self.mobile = false;
    // }
    self.isScreenSmall$.subscribe(bool => {
      self.mobile = false;
    });
    if (this.window) {
      this.window.scrollTo({
        top: 1,
        left: 10,
        behavior: 'smooth'
      });
      self.router.navigateByUrl(_path);
    } else {
      self.router.navigateByUrl(_path);
    }
  }
  ngOnDestroy(): void {
    const self = this;
    Object.keys(self.subscriptions).forEach(e => {
      self.subscriptions[e].unsubscribe();
    });
  }

  changeToArabic(val) {
    const self = this;
    self.localStorage.setItem('isArabic', val);
    if (self.window) {
      self.window.scrollTo(0, 0);
    }
    self.isArabic = val;
    self.window.location.reload();
  }
  getArabicData() {
  }
  getcommonData(lang) {
    const self = this;
    self.subscriptions['commonData'] = self.http.get(self.api.getUrl('WEBCOMMON') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.commonData = data['content'];
          if (self.commonData && self.commonData['logos'] && self.commonData['logos']['favicon']) {
            self.setFavIcon(self.commonData['logos']['favicon']);
          }
        }
      });
  }
  setFavIcon(val) {
    if (val) {
      document.getElementById('appIcon').setAttribute('href', val);
    } else {
      document.getElementById('appIcon').setAttribute('href', 'assets/images/favicon.png');
    }
  }
  ngAfterViewInit() {
    const self = this;
    setTimeout(() => {
      const checkScreenSize = () => document.body.offsetWidth <= 768;
      if (isPlatformBrowser(this.platform)) {
        const screenSizeChanged$ = Observable.fromEvent(this.window, 'resize').pipe(debounceTime(500)).pipe(map(checkScreenSize));
        self.isScreenSmall$ = screenSizeChanged$.pipe(startWith(checkScreenSize()));
        self.subscriptions['mob'] = self.isScreenSmall$.subscribe(bool => {
          self.mobile = false;
        });
      }
    }, 0);

  }
}
