import { ListChildRendererComponent } from './../../../../../shared/components/list-child';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-recommendation-list',
    templateUrl: './recommendation-list.component.html',
    styleUrls: ['./recommendation-list.component.scss']
})
export class RecommendationListComponent implements OnInit, OnDestroy {
    userForm: FormGroup;
    apiEndPoint: string;
    filterData: any;
    gridOptions: any;
    destroyChild = false;
    pagination: any;
    swapComponent = false;
    totalRowData: any;
    displayRequired = false;
    searchValue: any;
    params: any;
    @ViewChild('inputSearch') inputSearch: ElementRef;

    private paginationPageSize = 20;
    filterPresent = false;
    filter: any;
    private gridApi;
    private gridColumnApi;
    private frameworkComponents;
    private context;
    columnDefs;
    private components;
    dataSource: any;

    //
    roles: Array<any> = [];
    roleName: any = '';
    isLoading = false;
    records: Array<any> = [];
    totalSize = 0;
    curPage = 1;
    size = 20;
    subscriptions: any = {};

    constructor(
        private formBuilder: FormBuilder,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService
    ) {

        this.columnDefs = [
            {
                headerName: 'SI. No',
                width: 150,
                valueGetter: 'node.id',
                cellRenderer: 'rowIdRenderer'
            },
            {
                headerName: 'Full Name',
                field: 'fullname',
                minWidth: 300
            },
            {
                headerName: 'Designation',
                field: 'designation',
                minWidth: 200
            },
            {
                headerName: 'Manage',
                cellRenderer: 'recList',
                suppressMenu: true,
                suppressFilter: true,
            }
        ];
        this.context = { componentParent: this };
        this.frameworkComponents = {
            recList: ListChildRendererComponent
        };
        this.components = {
            rowIdRenderer: function (params) {
                return Number(params.rowIndex) + 1;
            },
        };
    }

    public ngOnInit() {
        const self = this;
        if (this.columnDefs) {
            this.configureGridSettings();
        }
    }

    configureGridSettings(): void {
        this.gridOptions = {
            frameworkComponents: this.frameworkComponents,
            enableFilter: false,
            components: this.components,
            context: this.context,
            enableSorting: false,
            enableColResize: true,
            rowDeselection: false,
            rowHeight: 45,
            pagination: true,
            columnDefs: this.columnDefs,
            headerHeight: 45,
        };
    }

    onReady(params: any): any {
        const self = this;
        self.gridApi = params.api;
        self.params = params;
        self.gridColumnApi = params.columnApi;
        self.httpService.get(self.api.getUrl('RECOMMENDATIONS'))
            .subscribe(data => {
                params.api.setRowData(data['properties']);
            });
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }
    edit(data) {
        const self = this;
        self._router.navigateByUrl('admin/testimonials/' + data['_id']);
    }
    delete(data) {
        const self = this;
        Swal.fire({
            title: 'Warning!',
            text: 'Recommendation will be deleted!',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'cancel',
            confirmButtonText: 'Ok'
        }).then(ok => {
            if (ok && ok['value']) {
                self.httpService.delete(self.api.getUrl('RECOMMENDATIONS') + '/' + data['_id'])
                    .subscribe((_res) => {
                        if (_res && _res['success']) {
                            Swal.fire({
                                title: 'Success!',
                                text: 'Recommendation Deleted Successfully.',
                                type: 'success',
                                confirmButtonText: 'Ok'
                            }).then(isConfirm => {
                                if (isConfirm) {
                                    this.params.filterModel = {};
                                    this.onReady(this.params);
                                }
                            });
                        }
                    }, err => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Unable to Delete Recommendation',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                    });
            }
        });
    }
    navigate() {
        const self = this;
        self._router.navigateByUrl('admin/testimonials/new');
    }

    onSearchChange(input) {
        const self = this;
        this.gridApi.setQuickFilter(self.searchValue);
    }

}
