import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormValidator {

  static validateForm(form) {
    const controls = form.controls;
    for (const control in controls) {
      if (controls[control]) {
        controls[control].markAsDirty();
        controls[control].markAsTouched();
        if (controls[control].controls) {
            FormValidator.validateForm(controls[control]);
        }
      }
    }
  }

  /*
  Custom Validators
   */
  static NotFound(control) {
    if (control.hasError('notFound')) {
      return {
        notFound: true
      };
    }
  }
}
