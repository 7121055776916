import { WebsiteLeadChildRendererComponent } from './../../../shared/components/website-lead-child';
import { SharedModule } from './../../../shared/shared.module';
import { WebsiteLeadListComponent } from './website-lead-list/website-lead-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { WebsiteLeadViewComponent } from './website-list-view/website-lead-view.component';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([WebsiteLeadChildRendererComponent]),
        ReactiveFormsModule,
        FormsModule,
        SharedModule
    ],
    declarations: [WebsiteLeadListComponent, WebsiteLeadViewComponent],
    exports: [WebsiteLeadListComponent, WebsiteLeadViewComponent],
    entryComponents: [WebsiteLeadViewComponent]
})
export class WebsiteLeadListModule { }
