import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {
  @Input() shrink = true;
  @Input() selected: any = 'dashboard';
  showSubmenu: boolean;
  showHistory: boolean;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  navigate(_path) {
    const self = this;
    self.showSubmenu = false;
    self.showHistory = false;
    if (_path) {
      self.selected = _path;
      self.router.navigateByUrl('/admin' + '/' + _path);
    }
  }
  toggleSubmenu() {
    const self = this;
    self.showSubmenu = !self.showSubmenu;
    self.showHistory = false;
  }
  toggleHistory() {
    const self = this;
    self.showHistory = !self.showHistory;
    self.showSubmenu = false;
  }
  hide() {
    const self = this;
    self.showSubmenu = false;
    self.showHistory = false;
  }

}
