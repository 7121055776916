import { ImageCropperComponent } from './../../../../shared/components/image-croppper/image-cropper.component';
import { CommonService } from './../../../../services/common.service';
import { ApiService } from './../../../../services/api.service';
import { HttpService } from './../../../../services/http.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { e } from '@angular/core/src/render3';

@Component({
    selector: 'app-logos',
    templateUrl: './logos.component.html',
    styleUrls: ['./logos.component.scss']
})
export class LogosComponent implements OnInit, OnDestroy {

    headerDarkLogo: any;
    headerDarkLogoUploaded = false;
    headerLightLogo: any;
    headerLightLogoUploaded = false;
    favicon: any;
    faviconUploaded = false;
    subscriptions = {};
    logos: any;
    logosId: any;

    constructor(
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private _cs: CommonService,
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        const self = this;
        self.getLogos();
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'headerDarkLogo') {
                self.headerDarkLogo = documents.path;
            } else if (documents && documents['type'] === 'headerLightLogo') {
                self.headerLightLogo = documents.path;
            } else if (documents && documents['type'] === 'favicon') {
                self.favicon = documents.path;
                // document.getElementById('appIcon').setAttribute('href', self.favicon);
            }
        });
     }
    onfaviconUpload(event: any): void {
        this.faviconUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'favicon');
    }
    onDeletefavicon(event) {
        this.faviconUploaded = event.isFileUploaded;
        this.favicon = null;
    }

    onHeaderDarkLogoUpload(event: any): void {
        this.headerDarkLogoUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'headerDarkLogo');
    }
    onHeaderLightLogoUpload(event) {
        this.headerLightLogoUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'headerLightLogo');
    }
    onDeleteHeaderDarkLogo(event) {
        const self = this;
        this.headerDarkLogoUploaded = event.isFileUploaded;
        self.headerDarkLogo = null;
    }
    onDeleteHeaderLightLogo(event) {
        const self = this;
        this.headerLightLogoUploaded = event.isFileUploaded;
        self.headerLightLogo = null;
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach( ele => {
            self.subscriptions[ele].unsubscribe();
        });
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    save() {
        const self = this;
        const payload = {
            favicon: self.favicon,
            h_dark_logo: self.headerDarkLogo,
            h_light_logo: self.headerLightLogo
        };
        if (self.logosId) {
            payload['_id'] = self.logosId;
        }
        if (self.favicon && self.headerDarkLogo && self.headerLightLogo) {
            self.subscriptions['aboutPageData'] = self.httpService.post(self.api.getUrl('CMSLOGOPAGE'), payload)
                .subscribe(_res => {
                    if (_res && _res['success']) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'Logo\'s saved successfully!',
                            type: 'success',
                            confirmButtonText: 'Ok'
                        });
                    }
                }, err => {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Fail to save Logo\'s.',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                });

        } else {
            Swal.fire({
                title: 'Warning',
                text: 'PLease Upload all the logo\'s.',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
        }
    }
    getLogos() {
        const self = this;
        self.httpService.get(self.api.getUrl('CMSLOGOPAGE')).subscribe(res => {
            if (res && res['success'] && res['page']) {
                self.logos = res['page'];
                self.logosId = res.page['_id'];
                if (self.logos && self.logos['favicon']) {
                    self.favicon = self.logos['favicon'];
                    self.faviconUploaded = true;
                }
                if (self.logos && self.logos['h_dark_logo']) {
                    self.headerDarkLogo = self.logos['h_dark_logo'];
                    self.headerDarkLogoUploaded = true;
                }
                if (self.logos && self.logos['h_light_logo']) {
                    self.headerLightLogo = self.logos['h_light_logo'];
                    self.headerLightLogoUploaded = true;
                }

            }
        });
    }
    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        // modalRef.componentInstance.aspectRatio = 1 / 1;
       // modalRef.componentInstance.resizeToWidth = 800;
    }
}

