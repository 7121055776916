import { ImageCropperComponent } from './../../../../shared/components/image-croppper/image-cropper.component';
import { ImageCropModule } from './../../../../shared/components/image-croppper/image-cropper.module';
import { SharedModule } from './../../../../shared/shared.module';
import { ListChildRendererComponent } from './../../../../shared/components/list-child';
import { AdminPropertyComponent } from './admin-property/admin-property.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AdminPropertyListComponent } from './admin-property-list/admin-property-list.component';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
    {
        path: '',
        component: AdminPropertyListComponent
    },
    {
        path: 'list',
        component: AdminPropertyListComponent
    },
    {
        path: 'new',
        component: AdminPropertyComponent
    },
    {
        path: ':propertyId',
        component: AdminPropertyComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([ListChildRendererComponent]),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        RouterModule,
        NgbModalModule,
        NgbTooltipModule,
        ImageCropModule
    ],
    declarations: [AdminPropertyComponent, AdminPropertyListComponent, AdminPropertyComponent],
    exports: [AdminPropertyComponent, AdminPropertyListComponent, AdminPropertyComponent],
    entryComponents: [ImageCropperComponent]
})
export class AdminPropertiesModule { }
