import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';

@Directive({
    selector: '[appPaginateButton]',
})
export class PaginateButtonDirective implements OnChanges {
    @Input() data = [];
    @Input() maxRows;
    @Input() pageNo;
    @Input() action;
    @Output() pageNoChange = new EventEmitter();
    @HostListener('click')
    onclick() {
        if (!!this.data && !!this.data.length && !!this.maxRows && !!this.pageNo && !!this.action && !!this.elementRef) {
            switch (this.action) {
                case 'Next':
                    if (!this.elementRef.nativeElement.disabled) {
                        this.pageNoChange.emit(this.pageNo + 1);
                    }
                    break;
                case 'Previous':
                    if (!this.elementRef.nativeElement.disabled) {
                        this.pageNoChange.emit(this.pageNo - 1);
                    }
                    break;
            }
        }
    }

    constructor(private elementRef: ElementRef) {}

    ngOnChanges() {
        switch (this.action) {
            case 'Next':
                this.elementRef.nativeElement.disabled =
                    !!this.data && !!this.data.length && !!this.maxRows && this.maxRows > 0
                        ? this.pageNo >= Math.ceil(this.data.length / this.maxRows)
                        : true;
                break;
            case 'Previous':
                this.elementRef.nativeElement.disabled = this.pageNo <= 1;
                break;
        }
    }
}
