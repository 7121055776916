import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paginate',
})
export class PaginatePipe implements PipeTransform {
    transform(arr: any[], maxRows: number, pageNo: number): any {
        const tempArr = [];
        tempArr.push(...arr);
        if (!arr || !arr.length) {
            return [];
        }
        tempArr.splice(0, (pageNo - 1) * maxRows);
        tempArr.splice(maxRows, arr.length);
        return tempArr;
    }
}
