import { ApiService } from './../../../../../../services/api.service';
import { HttpService } from './../../../../../../services/http.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-page-history-details',
    templateUrl: './page-history-details.component.html',
    styleUrls: ['./page-history-details.component.scss']
})
export class PageHistoryDetailsComponent implements OnInit , OnDestroy {
    subscriptions: any = {};
    page: any;
    historyId: any;
    pageId: any;
    historyArray: any;
    currentHistory: any;
    existingPage: any;
    constructor(
        private route: ActivatedRoute,
        private httpService: HttpService,
        private _router: Router,
        private api: ApiService
    ) { }

    ngOnInit(): void {
        const self = this;
        self.subscriptions['params'] = self.route.params.subscribe(data => {
            if (data && data['page']) {
                self.page = data['page'];
                self.getHistory(self.page);
                self.getExistingPageDetails(self.page);
            }
            if (data && data['historyId']) {
                self.historyId = data['historyId'];
            }
            if (data && data['pageId']) {
                self.pageId = data['pageId'];
            }
        });
    }
    getHistory(page) {
        const self = this;
        if (page) {
            self.httpService.get(self.api.getUrl('PAGES_HISTORY') + '/' + page)
                .subscribe(data => {
                    if (data && data['success'] && data['history']) {
                        self.historyArray = data['history'];
                        if (self.historyArray && self.historyArray.length) {
                            const found = _.find(self.historyArray, ['_id', self.historyId]);
                            if (found) {
                                self.currentHistory = found;
                            }
                        }
                    }
                });
        }
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }
    cancel() {
        const self = this;
        self._router.navigateByUrl('/admin/history/pages');
    }
    restore() {
        const self = this;
        if (self.pageId && self.historyId) {
            const params = {
                page_id: self.pageId,
                history_id: self.historyId
            };
            self.httpService.post(self.api.getUrl('PAGES_RESTORE') + '/' + self.page, params)
                .subscribe(data => {
                    if (data && data['success']) {
                        Swal.fire({
                            title: 'Success!',
                            text: `${self.page} page restored successfully!`,
                            type: 'success',
                            confirmButtonText: 'Ok'
                        });
                    }
                }, err => {
                    Swal.fire({
                        title: 'Error!',
                        text: `${self.page} page restore failed!`,
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
        }
    }

    getExistingPageDetails(page) {
        const self = this;
        if (page) {
            self.httpService.get(self.api.getUrl('PAGE_URL') + '/' + self.page)
                .subscribe(data => {
                    if (data && data['success']) {
                        self.existingPage = data['page'];
                    }
                }, err => {
                        console.log(err);
                });
        }
    }

}
