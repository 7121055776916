import { HttpService } from './../../../../services/http.service';
import { ApiService } from './../../../../services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE, WINDOW} from '@ng-toolkit/universal';



@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  careerForm: FormGroup;
  jobsData: any;
  @ViewChild('career') career: ElementRef;
  isArabic = false;
  subscriptions = {};

  constructor(private modalService: NgbModal,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    @Inject(WINDOW) private window: Window,
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private httpService: HttpService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    const self = this;
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.isArabic = true;
      self.getJobs('ar');
    } else {
      self.isArabic = false;
      self.getJobs('en');
    }
    self.buildForm();
  }

  buildForm() {
    const self = this;
    self.careerForm = self.formBuilder.group({
      'fullName': ['', Validators.required],
      'phone': ['', Validators.required],
      'email': ['', Validators.required],
      'resumePath': ['', Validators.required],
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
    }, (reason) => {

    });
  }

  save(modal) {
    const self = this;
    const formData = self.careerForm.getRawValue();
    self.httpService.postFormData(self.apiService.getUrl('CAREERS'), formData).subscribe(_res => {
      modal.dismiss();
    });
  }
  moveToCareers() {
    const self = this;
    self.career.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  getJobs(lang) {
    const self = this;
    self.subscriptions['getJobs'] = self.httpService.get(self.apiService.getUrl('WEBCAREERPAGE') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.jobsData = data['content'];
        }
      });
  }
  redirect(link, event) {
    if (link && self.window) {
      self.window.open(link, '_blank');
    }
  }
}
