import { ImageCropperComponent } from './../../../../../shared/components/image-croppper/image-cropper.component';
import { CommonService } from './../../../../../services/common.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit , OnDestroy {

    pageForm: FormGroup;
    videosUploadUrl = 'VIDEO_URL';
    slideUploadUrl = 'SLIDE_URL';
    bannerUploadUrl = 'BANNER_URL';
    subscriptions = {};
    selectedBannerTitle: any;
    selectedBannerSubTitle: any;
    selectedDescription: any;
    imageChangedEvent;
    croppedImage;
    bannerUploaded = false;
    logoUploaded = false;
    bannerImage: any;
    logoImage: any;
    formData: any;
    homePageData: any;
    homepageId: any;

    constructor(private fb: FormBuilder,
        private _cs: CommonService,
         private apiService: ApiService,
        private _router: Router,
        private modalService: NgbModal,
         private httpService: HttpService) { }

    ngOnInit(): void {
        const self = this;
        self.selectedBannerTitle = self.selectedBannerSubTitle = self.selectedDescription = 'english';
        self.buildForm();
        self.getHomePageData();
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'bannerImage') {
                self.bannerImage = documents.path;
            } else if (documents && documents['type'] === 'logoImage') {
                self.logoImage = documents.path;
            }
        });
    }

    buildForm() {
        const self = this;
        self.pageForm = self.fb.group({
            'bannerTitle': ['', Validators.required],
            'description': ['', Validators.required],
            'bannerTitleArabic': ['', Validators.required],
            'descriptionArabic': ['', Validators.required],
            'keywords': ['', Validators.required]
        });
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.pageForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }
    onImageUpload(event) {
        const self = this;
    }

    onDeleteFile() {
        const self = this;
    }
    reset() {
        const self = this;
        self.pageForm.reset();
    }

    save() {
        const self = this;
        const payload = self.generatePayload(self.pageForm.getRawValue());
        self.subscriptions['saveHomeData'] = self.httpService.post(self.apiService.getUrl('CMSHOMEPAGE'), payload)
        .subscribe(_res => {
                if (_res && _res['success']) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Home Page data created successfully!',
                        type: 'success',
                        confirmButtonText: 'Ok'
                    }).then((isConfirm) => {
                        if (isConfirm) {
                            self._router.navigateByUrl('admin/pages/home');
                        }
                    });
                }
            }, err => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Home Page data creation failed!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    }
    selectTabBannerTitle(tab) {
        const self = this;
        self.selectedBannerTitle = tab;
    }
    selectTabBannerSubTitle(tab) {
        const self = this;
        self.selectedBannerSubTitle = tab;
    }
    selectTabDescription(tab) {
        const self = this;
        self.selectedDescription = tab;
    }

    onBannerUpload(event: any): void {
        this.bannerUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'banner');
    }
    onLogoUpload(event) {
        this.logoUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'logo');
    }

    generatePayload(formData) {
        const self = this;
        const payload = {
            banner_image: self.bannerImage,
            banner_logo: self.logoImage,
            banner_caption_en: formData['bannerTitle'],
            banner_caption_ar: formData['bannerTitleArabic'],
            property_description_en: formData['description'],
            property_description_ar: formData['descriptionArabic'],
            keywords: formData['keywords']
        };
        if (self.homepageId) {
            payload['_id'] = self.homepageId;
        }
        return payload;
    }
    onDeleteBannerImage(event) {
        const self = this;
        this.bannerUploaded = event.isFileUploaded;
        self.bannerImage = null;
    }
    onDeleteLogo(event) {
        const self = this;
        this.logoUploaded = event.isFileUploaded;
        self.logoImage = null;
    }


    setFormData(formData) {
        const self = this;
        if (formData) {
            self.bannerUploaded = true;
            self.logoUploaded = true;
            self.bannerImage = formData['banner_image'];
            self.logoImage = formData['banner_logo'];
            self.pageForm.patchValue({
                bannerTitle: formData['banner_caption_en'],
                bannerTitleArabic:  formData['banner_caption_ar'],
                description:  formData['property_description_en'],
                descriptionArabic: formData['property_description_ar'],
                keywords: formData['keywords'],
            });
        }
    }

    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    getHomePageData() {
        const self = this;
        self.httpService.get(self.apiService.getUrl('CMSHOMEPAGE')).subscribe(res => {
            if (res && res['success'] && res['page']) {
                self.homePageData = res['page'];
                self.homepageId = res.page['_id'],
                    self.setFormData(res['page']);
            }
        });
    }
    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.aspectRatio = 16 / 9;
        if (type === 'bannerImage') {
            modalRef.componentInstance.resizeToWidth = 1920;
            modalRef.componentInstance.imageQuality = 40;
            modalRef.componentInstance.format = 'jpeg';
        } else if (type === 'logoImage') {
            modalRef.componentInstance.resizeToWidth = 500;
        }
    }
}

