import { PageHistoryModule } from './page/page-history.module';
import { PropertyHistoryModule } from './property/property-history.module';
import { EventsHistoryModule } from './events/events-history.module';
import { TestimonialHistoryModule } from './testimonial/testimonial-history.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';




@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        RouterModule,
        PropertyHistoryModule,
        PageHistoryModule,
        TestimonialHistoryModule,
        EventsHistoryModule
    ],
    declarations: [],
    exports: [],
    entryComponents: []
})
export class HistoryModule { }
