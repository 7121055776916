
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-list-child',
    template: `
    <div class="grid-cell">
    <a (click)="edit(params);" style="color:blue" class="pointer fa fa-pencil-square-o pr-2"></a>
    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
    <a (click)="delete(params)" *ngIf="params?.data?.username !=='superuser'" style="color:red" class=" pointer fa fa-trash"></a>
    <i class="fa fa-star ml-2" *ngIf="params?.data?.featured"></i>
    </div>
    `,
    styles: [
        `
        a {
            cursor: pointer;
        }
        .fa-star{
            color:orange;
        }
        .pointer{
              cursor: pointer;
        }
     `
    ]
})
export class ListChildRendererComponent implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }
    public edit(params) {
        this.params.context.componentParent.edit(params['data']);
    }

    public delete(params) {
        this.params.context.componentParent.delete(params['data']);
    }

    refresh(): boolean {
        return false;
    }
}
