import { SharedModule } from './../../shared/shared.module';
import { FooterModule } from './../footer/footer.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContactComponent } from './components/contact/contact.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule } from 'angular-google-recaptcha';


@NgModule({
    imports: [CommonModule, RouterModule, FooterModule, FormsModule,
        ReactiveFormsModule, SharedModule,
         RecaptchaModule.forRoot({
            siteKey: '6LfkzswUAAAAAMw6RYbxk-bSnIcnPDJRrjN0MGax',
        })
    ],
    declarations: [ContactComponent, ContactFormComponent],
    exports: [ContactComponent, ContactFormComponent]
})
export class ContactModule {}

