import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule],
    declarations: [LoginComponent],
})
export class LoginModule { }
