import { HttpService } from './../../../../services/http.service';
import { ApiService } from './../../../../services/api.service';
import { CommonService } from './../../../../services/common.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerData;
  isArabic = false;
  subscriptions = {};
  @Input() keywordsValue: any;
  keywords: FormControl = new FormControl(this.keywordsValue);


  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
    private api: ApiService,
    private http: HttpService,
    private _commonService: CommonService, @Inject(WINDOW) private window: Window) { }

  ngOnInit() {
    const self = this;
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.isArabic = true;
     self.getFooterData('ar');
    } else {
      self.isArabic = false;
      self.getFooterData('en');
    }
  }

  goTop() {
    if (this.window) {
      this.window.scrollTo(0, 0);
    }
  }
  getFooterData(lang) {
    const self = this;
    self.subscriptions['footerData'] = self.http.get(self.api.getUrl('WEBCOMMON') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.footerData = data['content'];
        }
      });
  }

}
