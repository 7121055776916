import { EventsHistoryViewComponent } from './events-history-view/events-history-view.component';
import { SharedModule } from './../../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EventsHistoryListComponent } from './events-history-list/events-history-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EventsHistoryDetailsComponent } from './events-history-details/events-history-details.component';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([]),
        SharedModule,
        RouterModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    declarations: [EventsHistoryListComponent, EventsHistoryViewComponent, EventsHistoryDetailsComponent],
    exports: [EventsHistoryListComponent, EventsHistoryViewComponent, EventsHistoryDetailsComponent],
    entryComponents: []
})
export class EventsHistoryModule { }
