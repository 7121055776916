import { ListChildRendererComponent } from './../../../../../shared/components/list-child';
import { ApiService } from './../../../../../services/api.service';
import { HttpService } from './../../../../../services/http.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Component({
    selector: 'app-job-posting-list',
    templateUrl: './job-posting-list.component.html',
    styleUrls: ['./job-posting-list.component.scss']
})
export class JobPostingListComponent implements OnInit, OnDestroy {
    userForm: FormGroup;
    apiEndPoint: string;
    filterData: any;
    gridOptions: any;
    destroyChild = false;
    pagination: any;
    totalRowData: any;
    displayRequired = false;
    params: any;
    @ViewChild('inputSearch') inputSearch: ElementRef;
    searchValue: any;

    private paginationPageSize = 20;
    pageNo = 1;
    filter: any;
    stopCall = true;
    totalSize: number;
    private gridApi;
    private gridColumnApi;
    private rowData: any[];
    private frameworkComponents;
    private context;
    columnDefs;
    private components;
    private getRowNodeId;
    subscriptions: any = {};

    constructor(
        private formBuilder: FormBuilder,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService
    ) {
        this.getRowNodeId = function (data) {
            return data.code;
        };

        this.columnDefs = [
            {
                headerName: 'SI. No',
                width: 150,
                valueGetter: 'node.id',
                cellRenderer: 'rowIdRenderer'
            },
            {
                headerName: 'Job Title',
                field: 'title_en',
                minWidth: 300
            },
            {
                headerName: 'Job Type',
                field: 'job_type',
                minWidth: 200
            },
            {
                headerName: 'Manage',
                cellRenderer: 'jobList',
                suppressMenu: true,
                suppressFilter: true,
            }
        ];
        this.context = { componentParent: this };
        this.frameworkComponents = {
            jobList: ListChildRendererComponent
        };
        this.components = {
            rowIdRenderer: function (params) {
                return Number(params.rowIndex) + 1;
            },
        };
    }

    public ngOnInit() {
        const self = this;
        if (this.columnDefs) {
            this.configureGridSettings();
        }
    }

    configureGridSettings(): void {
        this.gridOptions = {
            frameworkComponents: this.frameworkComponents,
            enableFilter: false,
            components: this.components,
            context: this.context,
            enableSorting: false,
            enableColResize: true,
            rowDeselection: false,
            rowHeight: 45,
            pagination: true,
            columnDefs: this.columnDefs,
            headerHeight: 45,
        };
    }

    onReady(params: any): any {
        const self = this;
        self.gridApi = params.api;
        self.params = params;
        self.gridColumnApi = params.columnApi;
        self.httpService.get(self.api.getUrl('JOBS'))
            .subscribe(data => {
                params.api.setRowData(data['jobs']);
            });
    }


    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }

    edit(data) {
        const self = this;
        self._router.navigateByUrl('admin/job-posting/' + data['_id']);
    }

    delete(data) {
        const self = this;
        Swal.fire({
            title: 'Warning!',
            text: 'Job Posting be deleted!',
            type: 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
        }).then(confirm => {
            if (confirm && confirm['value']) {
                self.httpService.delete(self.api.getUrl('JOBS') + '/' + data['_id'])
                    .subscribe(_res => {
                        if (_res && _res['success']) {
                            this.params.filterModel = {};
                            this.onReady(this.params);
                            Swal.fire({
                                title: 'Success!',
                                text: 'Job Posting Deleted Successfully!',
                                type: 'success',
                                confirmButtonText: 'Ok'
                            });
                        }
                    }, err => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Job Posting Deletion failed!',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                    });
            }
        });
    }
    navigate() {
        const self = this;
        self._router.navigateByUrl('admin/job-posting/new');
    }

    onSearchChange(input) {
        const self = this;
        this.gridApi.setQuickFilter(self.searchValue);
    }
}
