import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from './../../../../../../services/api.service';
import { HttpService } from './../../../../../../services/http.service';
import { WebsiteLeadChildRendererComponent } from './../../../../../../shared/components/website-lead-child';
import { CommonService } from './../../../../../../services/common.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-history-list',
    templateUrl: './page-history-list.component.html',
    styleUrls: ['./page-history-list.component.scss']
})
export class PageHistoryListComponent implements OnInit, OnDestroy {

    pages = [];
    selectedPage: any;
    swap = false;
    destroyChild = true;
    apiEndPoint: string;
    gridOptions: any;
    pagination: any;
    totalRowData: any;
    params: any;
    searchValue: any;
    dataSource: any;
    showGrid = false;
    private gridColumnApi;


    pageNo = 1;
    filter: any;
    stopCall = true;
    totalSize: number;
    private gridApi;
    private frameworkComponents;
    private context;
    columnDefs;
    private components;
    subscriptions: any = {};

    constructor(

        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private modalService: NgbModal,
        private _cs: CommonService
    ) {
        this.columnDefs = [
            {
                headerName: 'SI. No',
                width: 150,
                valueGetter: 'node.id',
                cellRenderer: 'rowIdRenderer'
            },
            {
                headerName: 'type',
                field: 'action',
                minWidth: 300
            },
            {
                headerName: 'timestamp',
                field: 'timestamp',
                minWidth: 200,
                valueFormatter: (params) => this._cs.defaultDateFormat(params, 'DD-MMM-YYYY hh:mm:ss A')

            },
            {
                headerName: 'username',
                field: 'username',
                minWidth: 200
            },
            {
                headerName: 'Manage',
                cellRenderer: 'leadsListUpdate',
                suppressMenu: true,
                suppressFilter: true
            }
        ];
        this.context = { componentParent: this };
        this.frameworkComponents = {
            leadsListUpdate: WebsiteLeadChildRendererComponent
        };
        this.components = {
            rowIdRenderer: function (params) {
                return Number(params.rowIndex) + 1;
            },
        };
    }

    public ngOnInit() {
        const self = this;
        self.pages = self._cs.getPages();
        if (this.columnDefs) {
            this.configureGridSettings();
        }
    }



    configureGridSettings(): void {
        this.gridOptions = {
            frameworkComponents: this.frameworkComponents,
            enableFilter: false,
            components: this.components,
            context: this.context,
            enableSorting: false,
            enableColResize: true,
            rowDeselection: false,
            rowHeight: 45,
            pagination: true,
            columnDefs: this.columnDefs,
            headerHeight: 45,
        };
    }

    onReady(params: any): any {
        const self = this;
        self.gridApi = params.api;
        self.params = params;
        self.gridColumnApi = params.columnApi;
        self.getPageData(params);

    }
    getPageData(params) {
        const self = this;
        self.httpService.get(self.api.getUrl('PAGES_HISTORY') + '/' + self.selectedPage)
            .subscribe(data => {
                if (data && data['success']) {
                    params.api.setRowData(data['history']);
                }
            });
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }

    view(data) {
        const self = this;
        if (!data['data']) {
            data['data'] = data;
        }
        if (data && data['_id'] && data['data'] && data['data']['_id']) {
            self._router.navigate(['admin/history/pages/' + self.selectedPage + '/' + data['_id'] + '/' + data['data']['_id']]);
        }
    }

    onPageSelect(p) {
        const self  = this;
        self.showGrid = true;
        self.destroyChild = !self.destroyChild;
    }
}
