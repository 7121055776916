import { ApiService } from './../../services/api.service';
import { HttpService } from './../../services/http.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})

export class EntryComponent implements OnInit {
  safeURL: any;
  player: any;
  subscriptions = {};
  video: any;
  logo: any;

  constructor(private _sanitizer: DomSanitizer,
    private http: HttpService,
    private api: ApiService) {
  }

  ngOnInit() {
    const self = this;
    self.getVideo();
  }

  getVideo() {
    const self = this;
    self.subscriptions['getVideo'] = self.http.get(self.api.getUrl('WEBINTROPAGE'))
      .subscribe(data => {
        if (data && data['success']) {
          self.video = data['content']['video_url'];
          self.logo = data['content']['logo'];
        }
      });
  }
}
