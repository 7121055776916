import { PropertyHistoryViewComponent } from './property-history-view/property-history-view.component';
import { SharedModule } from './../../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PropertyHistoryListComponent } from './property-history-list/property-history-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PropertyHistoryDetailsComponent } from './property-history-details/property-history-details.component';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([]),
        SharedModule,
        RouterModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        FormsModule,
    ],
    declarations: [PropertyHistoryListComponent, PropertyHistoryViewComponent, PropertyHistoryDetailsComponent],
    exports: [PropertyHistoryListComponent, PropertyHistoryViewComponent, PropertyHistoryDetailsComponent],
    entryComponents: []
})
export class PropertyHistoryModule { }
