import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AdminModule } from './modules/admin/admin.module';
import { FooterModule } from './modules/footer/footer.module';
import { ContactModule } from './modules/contact/contact.module';
import { AboutUsModule } from './modules/about us/about-us.module';
import { HomeModule } from './modules/home/home.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule } from '@angular/forms';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ProtectedModule } from './modules/protected/protected.module';
import { EntryComponent } from './modules/entry/entry.component';
import { CareerstModule } from './modules/careers/careers.module';
import { TestimonialModule } from './modules/testimonial/testimonial.module';
import { PropertiesModule } from './modules/properties/properties.module';
import { LoginModule } from './modules/login/login.module';
import { EntryModule } from './modules/entry/entry.module';
import { TokenInterceptor } from './services/http-interceptor.service';


@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        NgtUniversalModule,
        TransferHttpCacheModule,
        HttpClientModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        CarouselModule,
        HomeModule,
        ProtectedModule,
        AboutUsModule,
        ContactModule,
        PropertiesModule,
        FooterModule,
        AdminModule,
        AppRoutingModule,
        CareerstModule,
        TestimonialModule,
        AngularFontAwesomeModule,
        FormsModule,
        LoginModule,
        EntryModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
})
export class AppModule { }
