import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-protected',
  templateUrl: './protected.component.html',
  styleUrls: ['./protected.component.scss']
})
export class ProtectedComponent implements OnInit {

  hideNav: boolean;
  url: any;

  constructor(private router: Router) { }


  ngOnInit() {
    this.url = this.router.url;
    if (this.url === '/') {
      this.hideNav = true;
    }
  }

}
