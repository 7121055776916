import { HttpService } from './../../../../services/http.service';
import { ApiService } from './../../../../services/api.service';
import { CommonService } from './../../../../services/common.service';
import { Router, NavigationEnd } from '@angular/router';
import { OwlCarouselOptions } from './../../../../shared/constants/app.contants';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { WINDOW, LOCAL_STORAGE } from '@ng-toolkit/universal';
import { FormControl } from '@angular/forms';



@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent implements OnInit, OnDestroy {
    customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    dragging: true,
    dragClass: 'owl-drag',
    pullDrag: true,
    dots: true,
    navSpeed: 500,
    autoWidth: true,
    navText: ['&nbsp', '&nbsp'],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,

      },
      740: {
        items: 3,

      },
      940: {
        items: 3,
      },
      1260: {
        items: 3,

      }
    },
    nav: true
  };

  keywords: FormControl = new FormControl();

  @ViewChild('testimonial') testimonial: ElementRef;
  homeData: any;
  isArabic = false;
  subscriptions = {};
  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
    @Inject(WINDOW) private window: Window,
    private http: HttpService,
    private _router: Router,
    private api: ApiService
  ) { }

  ngOnInit() {
    const self = this;
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.isArabic = true;
      self.getTestimonials('ar');
    } else {
      self.isArabic = false;
      self.getTestimonials('en');
    }
  }


  moveToTestimonial() {
    const self = this;
    self.testimonial.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }

  ngOnDestroy(): void {
    const self = this;
  }

  getTestimonials(lang) {
    const self = this;
    self.subscriptions['getTestimonials'] = self.http.get(self.api.getUrl('WEBHOMEPAGE') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.homeData = data['content'];
          self.keywords.setValue(self.homeData['keywords']);
        }
      });
  }
  navigate(path) {
    const self = this;
    self._router.navigateByUrl(path);
    if (this.window) {
      this.window.scrollTo({
        top: 350,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

}
