import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from './../../../../../../services/api.service';
import { HttpService } from './../../../../../../services/http.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-events-history-details',
    templateUrl: './events-history-details.component.html',
    styleUrls: ['./events-history-details.component.scss']
})
export class EventsHistoryDetailsComponent implements OnInit {
    subscriptions = {};
    existingEvents;
    historyId;
    eventId;
    currentHistory;
    event_name: any;
    historyArray: any;
    constructor(private route: ActivatedRoute,
        private httpService: HttpService,
        private _router: Router,
        private api: ApiService) { }

    ngOnInit(): void {
        const self = this;
        self.subscriptions['routeData'] = self.route.data.subscribe(data => {
            if (data && data['events'] && data['events']['events']) {
                self.existingEvents = data['events']['events'];
            }
        });
        self.subscriptions['parama'] = self.route.params.subscribe(data => {
            if (data && data['historyId']) {
                self.historyId = data['historyId'];
            }
            if (data && data['eventId']) {
                self.eventId = data['eventId'];
                self.getHistory(self.eventId);
            }
        });
     }

     getHistory(id) {
         const self = this;
         if (id) {
             self.httpService.get(self.api.getUrl('EVENTS_HISTORY') + '/' + self.eventId)
                 .subscribe(data => {
                     if (data && data['success'] && data['history']) {
                         self.event_name = data['event_name'];
                         self.historyArray = data['history'];
                         if (self.historyArray && self.historyArray.length) {
                             const found = _.find(self.historyArray, ['_id', self.historyId]);
                             if (found) {
                                 self.currentHistory = found;
                             }
                         }
                     }
                 });
         }
     }

     cancel() {
         const self = this;
         self._router.navigateByUrl('/admin/history/events');
     }
    restore() {
        const self = this;
        if (self.eventId && self.historyId) {
            const params = {
                event_id: self.eventId,
                history_id: self.historyId
            };
            self.httpService.post(self.api.getUrl('EVENTS_RESTORE'), params)
                .subscribe(data => {
                    if (data && data['success']) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'event restored successfully!',
                            type: 'success',
                            confirmButtonText: 'Ok'
                        });
                    }
                }, err => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'event restore failed!',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                });
        }
     }
}
