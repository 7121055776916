import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-website-lead-view',
    templateUrl: './website-lead-view.component.html',
    styleUrls: ['./website-lead-view.component.scss']
})
export class WebsiteLeadViewComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) { }

    websiteData: any;
    ngOnInit(): void { }

    close() {
        this.activeModal.close();
    }
}
