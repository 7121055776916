import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Router } from '@angular/router';
import { Component, OnInit , Inject} from '@angular/core';
import { fadeSlideUpAnimation } from './../../../shared/animations/animation';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
  animations: [fadeSlideUpAnimation],
})
export class AdminComponent implements OnInit {

  shrink = false;
  user: any;

  constructor(@Inject(LOCAL_STORAGE) private localStorage: any,
  private auth: AuthService,
  private router: Router) {

  }
  ngOnInit() {
    const self = this;
    self.user = self.auth.getUser();

  }
  public getRouterOutletState(outlet) {
    return outlet.isActivated && outlet.activatedRouteData.animate ? outlet.activatedRoute : '';
  }
  toggleMenu() {
    const self = this;
    self.shrink = !self.shrink;
  }
  logout() {
    const self = this;
    self.localStorage.removeItem('Authorization');
    self.localStorage.removeItem('User');
    self.router.navigateByUrl('/home');
  }
}
