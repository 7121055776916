import { WebsiteLeadViewComponent } from './../website-list-view/website-lead-view.component';
import { WebsiteLeadChildRendererComponent } from './../../../../shared/components/website-lead-child';
import { HttpService } from './../../../../services/http.service';
import { ApiService } from './../../../../services/api.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-website-lead-list',
    templateUrl: './website-lead-list.component.html',
    styleUrls: ['./website-lead-list.component.scss']
})
export class WebsiteLeadListComponent implements OnInit, OnDestroy {

    leadForm: FormGroup;
    apiEndPoint: string;
    filterData: any;
    gridOptions: any;
    destroyChild = false;
    pagination: any;
    swapComponent = false;
    totalRowData: any;
    displayRequired = false;
    params: any;
    searchValue: any;
    dataSource: any;
    @ViewChild('inputSearch') inputSearch: ElementRef;

    private paginationPageSize = 20;
    pageNo = 1;
    filter: any;
    stopCall = true;
    totalSize: number;
    private gridApi;
    private gridColumnApi;
    private rowData: any[];
    private frameworkComponents;
    private context;
    columnDefs;
    private components;
    private getRowNodeId;
    subscriptions: any = {};

    constructor(
        private formBuilder: FormBuilder,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private modalService: NgbModal
    ) {

        this.columnDefs = [
            {
                headerName: 'SI. No',
                width: 150,
                valueGetter: 'node.id',
                cellRenderer: 'rowIdRenderer'
            },
            {
                headerName: 'name',
                field: 'name',
                minWidth: 300
            },
            {
                headerName: 'email',
                field: 'email',
                minWidth: 200
            },
            {
                headerName: 'phone',
                field: 'phone',
                minWidth: 200
            },
            {
                headerName: 'Manage',
                cellRenderer: 'leadsListUpdate',
                suppressMenu: true,
                suppressFilter: true
            }
        ];
        this.context = { componentParent: this };
        this.frameworkComponents = {
            leadsListUpdate: WebsiteLeadChildRendererComponent
        };
        this.components = {
            rowIdRenderer: function (params) {
                return Number(params.rowIndex) + 1;
            },
        };
    }

    public ngOnInit() {
        const self = this;
        if (this.columnDefs) {
            this.configureGridSettings();
        }
        self.buildDocumentForm();
    }

    buildDocumentForm() {
        const self = this;
        self.leadForm = self.formBuilder.group({
            'name': ['', []],
            'email': ['', []],
        });

    }

    configureGridSettings(): void {
        this.gridOptions = {
            frameworkComponents: this.frameworkComponents,
            enableFilter: false,
            components: this.components,
            context: this.context,
            enableSorting: false,
            enableColResize: true,
            rowDeselection: false,
            rowHeight: 45,
            pagination: true,
            columnDefs: this.columnDefs,
            headerHeight: 45,
        };
    }

    onReady(params: any): any {
        const self = this;
        self.gridApi = params.api;
        self.params = params;
        self.gridColumnApi = params.columnApi;
        self.httpService.get(self.api.getUrl('LEADS'))
            .subscribe(data => {
                params.api.setRowData(data['leads']);
            });
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }

    view(data) {
        const self = this;
        const modal = self.modalService.open(WebsiteLeadViewComponent, { size: 'lg', backdrop: 'static' });
        modal.componentInstance.websiteData = data['description'];
    }

    delete(data) {
        const self = this;
        Swal.fire({
            title: 'Warning!',
            text: 'Lead will be deleted!',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'cancel',
            confirmButtonText: 'Ok'
        }).then(ok => {
            if (ok && ok['value']) {
                self.httpService.delete(self.api.getUrl('LEADS') + '/' + data['_id'])
                    .subscribe((_res) => {
                        if (_res && _res['success']) {
                            Swal.fire({
                                title: 'Success!',
                                text: 'Lead Deleted Successfully.',
                                type: 'success',
                                confirmButtonText: 'Ok'
                            }).then(isConfirm => {
                                if (isConfirm) {
                                    this.params.filterModel = {};
                                    this.onReady(this.params);
                                }
                            });
                        }
                    }, err => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Unable to Delete Lead',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                    });
            }
        });
    }
    // navigate() {
    //     const self = this;
    //     self._router.navigateByUrl('admin/user-management/new');
    // }

    onSearchChange(input) {
        const self = this;
        this.gridApi.setQuickFilter(self.searchValue);
    }
}
