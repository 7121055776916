import { ApiService } from './../../../../../services/api.service';
import { HttpService } from './../../../../../services/http.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-job-posting',
    templateUrl: './job-posting.component.html',
    styleUrls: ['./job-posting.component.scss'],
    animations : [
        trigger('fadeInOut', [
            state('void', style({
                opacity: 0
            })),
            transition('void <=> *', animate(1000)),
        ]),
    ]
})
export class JobPostingComponent implements OnInit {
    jobListingForm: FormGroup;
    requirementForm: FormGroup;
    requirementFormAr: FormGroup;
    tagsForm: FormGroup;
    subscriptions: any = {};
    jobId: any;
    selectedJobTitle: any;
    selectedJobDescription: any;
    @ViewChild('requirementsAr') requirementsAr: ElementRef;
    @ViewChild('requirements') requirements: ElementRef;
    @ViewChild('tags') tags: ElementRef;
    jobsData: any;
    jobData: any;



    constructor(private formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService
    ) {

    }

    ngOnInit() {
        const self = this;
        self.selectedJobDescription = self.selectedJobTitle = 'english';
        self.subscriptions['params'] = self._route.params.subscribe((params: Params) => {
            if (params && params['jobId']) {
                self.jobId = params['jobId'];
                self.getJobyId(self.jobId);
            }
        });
        self.buildForm();
        self.buildrequirementForm();
        self.buildrequirementFormAr();
        self.buildTagsForm();
    }


    buildForm() {
        const self = this;
        const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        self.jobListingForm = self.formBuilder.group({
            'jobTitle': ['', Validators.required],
            'jobDescription': ['', Validators.required],
            'jobTitleArabic': ['', Validators.required],
            'jobDescriptionArabic': ['', Validators.required],
            'jobType': ['', Validators.required],
            'hourlyRateFrom': ['', Validators.required],
            'hourlyRateTo': ['', Validators.required],
            'jobRedirection' : ['', Validators.pattern(reg)],
            'experience': ['', Validators.required],
        });
    }
    buildrequirementForm() {
        const self = this;
        self.requirementForm = self.formBuilder.group({
            'requirement': self.formBuilder.array([])
        });
        if (!self.jobId) {
            self.onAddRequirement();
        }
    }
    buildrequirementFormAr() {
        const self = this;
        self.requirementFormAr = self.formBuilder.group({
            'requirement': self.formBuilder.array([])
        });
        if (!self.jobId) {
            self.onAddRequirementAr();
        }
    }
    buildTagsForm() {
        const self = this;
        self.tagsForm = self.formBuilder.group({
            'tag': self.formBuilder.array([])
        });
        if (!self.jobId) {
            self.onAddTag();
        }
    }
    onAddRequirement(req?) {
        const self = this;
        const requirementArrayControls = <FormArray>self.requirementForm.get('requirement');
        const newFormControlName: AbstractControl = new FormControl(req ? req.requirement : '');
        const newFormGroup = new FormGroup({});
        newFormGroup.addControl('name', newFormControlName);
        requirementArrayControls.push(newFormGroup);
    }
    onAddRequirementAr(req?) {
        const self = this;
        const requirementArrayControls = <FormArray>self.requirementFormAr.get('requirement');
        const newFormControlName: AbstractControl = new FormControl(req ? req.requirement : '');
        const newFormGroup = new FormGroup({});
        newFormGroup.addControl('name', newFormControlName);
        requirementArrayControls.push(newFormGroup);
    }
    removeRequirementAr(index) {
        const self = this;
        const attributeArrayControls = <FormArray>self.requirementFormAr.get('requirement');
        if (attributeArrayControls.length === 1) {
            self.requirementFormAr.reset();
            self.requirementsAr.nativeElement.reset();
        } else {
            attributeArrayControls.removeAt(index);
        }

    }
    removeRequirement(index) {
        const self = this;
        const attributeArrayControls = <FormArray>self.requirementForm.get('requirement');
        if (attributeArrayControls.length === 1) {
            self.requirementForm.reset();
            self.requirements.nativeElement.reset();
        } else {
            attributeArrayControls.removeAt(index);
        }

    }
    onAddTag(tag?) {
        const self = this;
        const tagArrayControls = <FormArray>self.tagsForm.get('tag');
        const newFormControlName: AbstractControl = new FormControl(tag ? tag : '');
        const newFormGroup = new FormGroup({});
        newFormGroup.addControl('name', newFormControlName);
        tagArrayControls.push(newFormGroup);
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.jobListingForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }
    removeTag(index) {
        const self = this;
        const tagArrayControls = <FormArray>self.tagsForm.get('tag');
        if (tagArrayControls.length === 1) {
            self.tagsForm.reset();
            self.tags.nativeElement.reset();
        } else {
            tagArrayControls.removeAt(index);
        }

    }

    setFormData(res) {
        const formData = res['job'];
        const self = this;
        if (formData) {
            const reqAr = formData['requirement_ar'];
            const reqEn = formData['requirement_en'];
            const tags = formData['tags'];
            if (Array.isArray(tags)) {
                tags.forEach((tag) => {
                    self.onAddTag(tag);
                });
            }
            if (Array.isArray(tags) && tags.length === 0) {
                self.onAddTag();
            }
            if (Array.isArray(reqAr)) {
                reqAr.forEach((req) => {
                    self.onAddRequirementAr(req);
                });
            }
            if (Array.isArray(reqEn) && reqEn.length === 0) {
                self.onAddRequirementAr();
            }
            if (Array.isArray(reqEn)) {
                reqEn.forEach((req) => {
                    self.onAddRequirement(req);
                });
            }
            if (Array.isArray(reqEn) && reqEn.length === 0) {
                self.onAddRequirement();
            }
            self.jobListingForm.patchValue({
                'jobTitle': formData['title_en'],
                'jobTitleArabic': formData['title_ar'],
                'jobDescription': formData['description_en'],
                'jobDescriptionArabic': formData['description_ar'],
                'jobType': formData['job_type'],
                'currencyType': formData['rate_currency'],
                'hourlyRateFrom': formData['rate_min'],
                'hourlyRateTo': formData['rate_max'],
               // 'requirement': formData['requirements'],
              //  'tag': formData['tags'],
                'experience': formData['experience'],
                'jobRedirection': formData['job_redirection_link']
            });
        }
    }
    reset() {
        const self = this;
        self.jobListingForm.reset();
    }

    getJobsList(formData) {
        const self = this;
        self.httpService.get(self.api.getUrl('JOBS')).subscribe(res => {
            self.jobsData = res;
            self.setFormData(res);
        });
    }

    generatePayload(formData) {
        const self = this;
        const requirements = self.requirementForm.getRawValue()['requirement'];
        const requirementsAr = self.requirementFormAr.getRawValue()['requirement'];
        const requireMentArray = [];
        const requireMentArrayAr = [];
        requirements.forEach((item, index) => {
            const obj = {
                order : index + 1,
                requirement : item['name'],
            };
            requireMentArray.push(obj);
        });
        requirementsAr.forEach((item, index) => {
            const obj = {
                order: index + 1,
                requirement: item['name'],
            };
            requireMentArrayAr.push(obj);
        });
        const tags = self.tagsForm.getRawValue()['tag'];
        const tagArray = [];
        tags.forEach(item => {
            const tag = item['name'];
            tagArray.push(tag);
        });

        const payload = {
            'active': formData['active'] ? formData['active'] : true,
            'title_en': formData['jobTitle'],
            'title_ar': formData['jobTitleArabic'],
            'description_en': formData['jobDescription'],
            'description_ar': formData['jobDescriptionArabic'],
            'job_type': formData['jobType'], // Full Time or Part Time or Freelancer
            'rate_currency': formData['currencyType'] ? formData['currencyType'] : 'KWD', // KWD or USD
            'rate_min': formData['hourlyRateFrom'],
            'rate_max': formData['hourlyRateTo'],
            'requirement_en': requireMentArray,
            'requirement_ar': requireMentArrayAr,
            'tags': tagArray,
            'experience': formData['experience'],
            'job_redirection_link': formData['jobRedirection']
        };
        if (self.jobId) {
            payload['_id'] = self.jobId;
        }
        return payload;
    }

    getJobyId(id) {
        const self = this;
        self.httpService.get(self.api.getUrl('JOBS') + '/' + id).subscribe((_res) => {
            self.setFormData(_res);
        });
    }

    save() {
        const self = this;
        const formData = self.jobListingForm.getRawValue();
        const payload = self.generatePayload(formData);
        if (self.jobListingForm.valid) {
            if (self.jobId) {
                self.updateUser(payload);
            } else {
                self.subscriptions['createUser'] = self.httpService.put(self.api.getUrl('JOBS'), payload)
                    .subscribe(_res => {
                        Swal.fire({
                            title: 'Success!',
                            text: 'Job Created successfully!',
                            type: 'success',
                            confirmButtonText: 'Ok'
                        }).then((isConfirm) => {
                            if (isConfirm) {
                                self._router.navigateByUrl('admin/job-posting/list');
                            }
                        });
                    }, err => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Job creation failed!',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                    });
            }
        }
    }

    updateUser(formData) {
        const self = this;
        self.subscriptions['updateUser'] = self.httpService.post(self.api.getUrl('JOBS'), formData)
            .subscribe(_res => {
                Swal.fire({
                    title: 'Success!',
                    text: 'Job Updated successfully!',
                    type: 'success',
                    confirmButtonText: 'Ok'
                }).then((isConfirm) => {
                    if (isConfirm) {
                        self._router.navigateByUrl('admin/job-posting/list');
                    }
                });
            }, err => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Job Updation failed!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    }

    redirect(link, event) {
        if (link && link['value'] && window) {
            window.open(link['value'], '_black');
        }
    }

    selectTabJobDescription(tab) {
        const self = this;
        self.selectedJobDescription = tab;
    }
    selectTabJobTitle(tab) {
        const self = this;
        self.selectedJobTitle = tab;
    }
}
