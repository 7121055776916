import { HttpService } from './../../../../services/http.service';
import { ApiService } from './../../../../services/api.service';
import { CommonService } from './../../../../services/common.service';
import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

contactData: any;
  @ViewChild('contact') contact: ElementRef;
  isArabic = false;
  subscriptions = {};

  constructor(
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private api: ApiService,
    private http: HttpService) {

    }

  ngOnInit() {
    const self = this;
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.isArabic = true;
      self.getContactData('ar');
    } else {
      self.isArabic = false;
      self.getContactData('en');
    }
  }
  moveToContact() {
    const self = this;
    self.contact.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  getContactData(lang) {
    const self = this;
    self.subscriptions['getContactData'] = self.http.get(self.api.getUrl('WEBCONTACTPAGE') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.contactData = data['content'];
        }
      });
  }
}
