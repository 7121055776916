import { animate, query, style, transition, trigger, state } from '@angular/animations';

export const fadeSlideUpAnimation = trigger('fadeSlideUpAnimation', [
    transition('* => *', [
        query(
            ':enter',
            [
                style({
                    opacity: 0,
                    transform: 'translateY(30px)',
                }),
            ],
            { optional: true }
        ),

        query(
            ':leave',
            [
                style({ opacity: 1 }),
                animate(
                    '0.2s',
                    style({
                        opacity: 0,
                    })
                ),
            ],
            { optional: true }
        ),

        query(
            ':enter',
            [
                style({ opacity: 0 }),
                animate(
                    '0.2s',
                    style({
                        opacity: 1,
                        transform: 'translateY(0px)',
                    })
                ),
            ],
            { optional: true }
        ),
    ]),
]);

export const fadeInOutAnimation = [
    trigger('fadeInOutAnimation', [
        transition(':enter', [style({ opacity: 0 }), animate('0.2s', style({ opacity: 1 }))]),
        transition(':leave', [style({ opacity: 1 }), animate('0.2s', style({ opacity: 0 }))]),
    ]),
];

export const saleem = [
    trigger('fadeInOut', [
    state('void', style({
        opacity: 0
    })),
    transition('void <=> *', animate(1000)),
])
];
