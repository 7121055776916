import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { ApiService } from '../../../services/api.service';
import { AuthService } from '../../../services/auth.service';
import { HttpService } from '../../../services/http.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private http: HttpService, private auth: AuthService, private api: ApiService) { }

    login(userDetails: any) {
        return this.http.post(this.api.getUrl('LOGIN'), userDetails).pipe(
            tap(
                value => {
                    this.auth.setToken(value['token']);
                },
                error => {
                    this.handleError(error);
                }
            )
        );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error.error}`);
        }
    }
}
