import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Injectable, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user: any;
    constructor(@Inject(LOCAL_STORAGE) private localStorage: any, ) {}

    getToken() {
        return this.localStorage.getItem('Authorization');
    }
    setToken(loggedInUser) {
        this.localStorage.setItem('Authorization', loggedInUser);
    }
    loggedIn() {
        return !!this.localStorage.getItem('Authorization');
    }
    setUser(user) {
        this.localStorage.setItem('User', user);
    }
    getUser() {
        return this.localStorage.getItem('User');
    }
}
