import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root',
})
export class CommonService {

    pages = [
        {
            'name': 'Home Page',
            'api': 'home'
        },
        {
            'name': 'Property',
            'api': 'property'
        },
        {
            'name': 'About Page',
            'api': 'about'
        },
        {
            'name': 'Contact Page',
            'api': 'contact'
        },
        {
            'name': 'Career Page',
            'api': 'career'
        },
        {
            'name': 'Logo Page',
            'api': 'logo'
        },
        {
            'name': 'Intro Page',
            'api': 'intro'
        },
        {
            'name': 'Testimonial Page',
            'api': 'testimonial'
        }
    ];

    constructor() { }
    notifier$: Subject<any> = new Subject();
    changeToArabic$: Subject<any> = new Subject();
    documents$: Subject<any> = new Subject();
    allImages$: Subject<any> = new Subject();
    documents = [];
    fileUploaded: Subject<any> = new Subject();
    setNotifier(message, type) {
        this.notifier$.next({
            message,
            type,
        });
    }
    changeToArabic(val) {
        this.changeToArabic$.next({
            isArabic: val
        });
    }
    addDocument(file: any, type?): void {
        const document = {};
        document['destination'] = file['destination'];
        document['filename'] = file['filename'];
        document['originalname'] = file['originalname'];
        document['path'] = file['path'];
        if (type) {
            document['type'] = type;
        }
        this.documents.push(document);
        this.allImages$.next(this.documents);
        this.documents$.next(document);
    }

    removeDocument(documentId: any) {
        const index = this.documents.findIndex(e => e.documentType._id === documentId);
        if (index !== -1) {
            this.documents.splice(index, 1);
            this.documents$.next(this.documents);
        }
    }

    getPages() {
        return this.pages;
    }
    defaultDateFormat(params: any, format?: string) {
        if (params.value) {
            const dateFormat: string = format;
            return moment(params.value).format(dateFormat);
        } else {
            return 'N/A';
        }
    }
}
