import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private api: ApiService, private http: HttpService, private auth: AuthService, private _router: Router) {}

    canActivate(): boolean {
        if (this.auth.loggedIn()) {
            return true;
        } else {
            this._router.navigate(['/login']);
            return false;
        }
    }
}
