import { CommonService } from './../../../../services/common.service';
import { HttpService } from './../../../../services/http.service';
import { ApiService } from './../../../../services/api.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  contactData: any;
  contactForm: FormGroup;
  isArabic = false;
  subscriptions = {};
  phone = '^\\+?[0-9]{0,}(?=.*)[- ()0-9]*$';

  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpService,
    private api: ApiService,
    @Inject(LOCAL_STORAGE) private localStorage: any,
    private http: HttpService,
    private _commonService: CommonService) { }

  ngOnInit() {
    const self = this;
    const isArabic = self.localStorage.getItem('isArabic');
    if (isArabic === 'true') {
      self.isArabic = true;
      self.getContactData('ar');
    } else {
      self.isArabic = false;
      self.getContactData('en');
    }
    self.buildForm();
  }
  buildForm() {
    const self = this;
    self.contactForm = self.formBuilder.group({
      'name': ['', Validators.required],
      'email': ['', [Validators.email, Validators.required]],
      'phone': ['', [ Validators.required, Validators.pattern(self.phone)]],
      'message': ['', Validators.required],
    'recaptcha': [null, Validators.required]

    });
  }

  generatePayload(formData) {
    const self = this;
    const payload = {
      lang_en: self.isArabic ? false : true,
      name : formData['name'],
      email : formData['email'],
      phone : formData['phone'],
      description : formData['message'],
    };
    return payload;
  }
  sendMessage(recaptcha) {
    const self = this;
    const formData = self.generatePayload(self.contactForm.getRawValue());
    if (self.contactForm.valid) {
      self.httpService.post(self.api.getUrl('CONTACTFORM'), formData)
        .subscribe(res => {
          if (res && res['success']) {
            self.contactForm.reset();
            recaptcha.recaptchaAPI.reset();
            Swal.fire({
              title: 'Thanks.',
              text: 'Your email has been successfully sent and we appreciate you for contacting us. We\'ll be in touch soon.',
              type: 'success',
              confirmButtonText: 'Ok'
            });
          }
        }, err => {
          Swal.fire({
            title: 'Please try again',
            text: 'Something went wrong!',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
    }
}
  getContactData(lang) {
    const self = this;
    self.subscriptions['getContactData'] = self.http.get(self.api.getUrl('WEBCONTACTPAGE') + '/' + lang)
      .subscribe(data => {
        if (data && data['success']) {
          self.contactData = data['content'];
        }
      });
  }
  onScriptLoad() {
  }

  onScriptError() {
    console.log('Something went long when loading the Google reCAPTCHA');
  }
  getfield(controlName: string) {
    const controls = controlName.split('.');
    let currentCtrl = this.contactForm.controls[controls[0]];
    controls.forEach((ctrl, i) => {
      if (i !== 0) {
        currentCtrl = currentCtrl['controls'][ctrl];
      }
    });
    return currentCtrl;
  }
}
