import { ImageCropperComponent } from './../../../../../shared/components/image-croppper/image-cropper.component';
import { CommonService } from './../../../../../services/common.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-add-update-recommendation',
    templateUrl: './add-update-recommendation.component.html',
    styleUrls: ['./add-update-recommendation.component.scss']
})
export class AddUpdateRecommendationComponent implements OnInit , OnDestroy {
    recommendationForm: FormGroup;
    subscriptions: any = {};
    recId: any;
    uploadUrl: any = 'uploadUrl';
    selectedRecommendation: any;
    selectedName: any;
    selectedDesignation: any;
    isFileUploaded = false;
    avatar: any;
    formData: any;

    constructor(private formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private _cs: CommonService,
        private modalService: NgbModal
    ) {

    }

    ngOnInit() {
        const self = this;
        self.selectedRecommendation = self.selectedName = self.selectedDesignation = 'english';
        self.buildForm();
        self.subscriptions['params'] = self._route.params.subscribe((params: Params) => {
            if (params && params['recId']) {
                self.recId = params['recId'];
                self.getRecommendationById(self.recId);
            }
        });
        self.subscriptions['documents'] =  self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'avatar') {
                self.avatar = documents.path;
            }
        });
    }


    buildForm() {
        const self = this;
        self.recommendationForm = self.formBuilder.group({
            'fullName': ['', Validators.required],
            'designation': ['', Validators.required],
            'recommendation': ['', [Validators.required, Validators.minLength, Validators.maxLength]],
            'fullNameArabic': ['', Validators.required],
            'designationArabic': ['', Validators.required],
            'recommendationArabic': ['', [Validators.required, Validators.minLength, Validators.maxLength]],
            'active': ['', Validators.required],
        });
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.recommendationForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }

    setFormData(data) {
        const self = this;
        const formData = data['recommendation'];
        if (formData) {
            if (formData['avatar']) {
                self.avatar = formData['avatar'];
                self.isFileUploaded = true;
            }
            self.recommendationForm.patchValue({
                'active': formData['active'],
                'fullName': formData['fullname_en'],
                'fullNameArabic': formData['fullname_ar'],
                'designation': formData['designation_en'],
                'designationArabic': formData['designation_ar'],
                'recommendation': formData['recommendation_en'],
                'recommendationArabic': formData['recommendation_ar']
            });
        }
    }
    reset() {
        const self = this;
        self.recommendationForm.reset();
    }
    generatePayload(formData) {
        const self = this;
        const payload = {
            active: formData['active'],
            fullname_en: formData['fullName'],
            fullname_ar: formData['fullNameArabic'],
            designation_en: formData['designation'],
            designation_ar: formData['designationArabic'],
            recommendation_en: formData['recommendation'],
            recommendation_ar: formData['recommendationArabic'],
            avatar: self.avatar
        };
        if (self.recId) {
            payload['_id'] = self.recId;
        }
        return payload;
    }

    getRecommendationById(id) {
        const self = this;
        self.httpService.get(self.api.getUrl('RECOMMENDATIONS') + '/' + id).subscribe((_res) => {
            self.formData = _res['recommendation'];
            self.setFormData(_res);
        });
    }

    save() {
        const self = this;
        const formData = self.recommendationForm.getRawValue();
        const payload = self.generatePayload(formData);
        if (self.avatar) {
            if (self.recommendationForm.valid) {
                if (self.recId) {
                    self.updateRecommendation(payload);
                } else {
                    self.subscriptions['Testimonial'] = self.httpService.put(self.api.getUrl('RECOMMENDATIONS'), payload)
                        .subscribe(_res => {
                            if (_res && _res['success']) {
                                Swal.fire({
                                    title: 'Success!',
                                    text: 'Testimonial created successfully!',
                                    type: 'success',
                                    confirmButtonText: 'Ok'
                                }).then((isConfirm) => {
                                    if (isConfirm) {
                                        self._router.navigateByUrl('admin/testimonials/list');
                                    }
                                });
                            }
                        }, err => {
                            Swal.fire({
                                title: 'Error!',
                                text: 'Testimonial creation failed!',
                                type: 'error',
                                confirmButtonText: 'Ok'
                            });
                        });
                }
            }
        } else {
            Swal.fire({
                title: 'Warning!',
                text: 'Please Upload Avatar!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
        }
    }

    updateRecommendation(payload) {
        const self = this;
        self.subscriptions['updateTestimonial'] = self.httpService.post(self.api.getUrl('RECOMMENDATIONS'), payload)
            .subscribe(_res => {
                if (_res && _res['success']) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Testimonial updated successfully!',
                        type: 'success',
                        confirmButtonText: 'Ok'
                    }).then((isConfirm) => {
                        if (isConfirm) {
                            self._router.navigateByUrl('admin/testimonials/list');
                        }
                    });
                }
            }, err => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Testimonial updation failed!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    }

    onAvatarUpload(event) {
    }
    onAddressRemove() {
    }

    selectTabName(tab) {
        const self = this;
        self.selectedName = tab;
    }

    selectTabDesignation(tab) {
        const self = this;
        self.selectedDesignation = tab;
    }
    selectTabRecommendation(tab) {
        const self = this;
        self.selectedRecommendation = tab;
    }
    onImageUpload(event: any): void {
        this.isFileUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file);
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    onDeleteFile(event) {
        const self = this;
        this.isFileUploaded = event.isFileUploaded;
        self.avatar = '';
    }
    viewFile() {
        const self = this;
        const finalUrl = self.api.getUrl('IMAGE') + self.avatar;
        window.open(finalUrl);

    }
    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.aspectRatio = 16 / 9;
        modalRef.componentInstance.resizeToWidth = 1024;
        modalRef.componentInstance.onlyScaleDown = true;
        modalRef.componentInstance.maintainAspectRatio = true;
        modalRef.componentInstance.imageQuality = 100;
    }
    ngOnDestroy() {
        const self = this;
        self.avatar = null;
        self._cs.documents = [];
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }
}

