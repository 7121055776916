var $ = require('jquery');

    //---- Nav Menu Dropdown
export var dropdown = function () {
   $('.header-3').each(function () {
        $('.nav-menu .drop').hover(function () {
            $(this).find('.dropdown').slideDown("slow");
        });
        $('.nav-menu .drop').mouseleave(function () {
            $(this).find('.dropdown').slideUp("slow");
        });
    });
}
    //---------- Side Header JS
export var sideHeader = function()  {
$('.header-3').each(function () {
        $('#toggle-header').click(function () {
            if ($('#toggle-header').hasClass('show-header')) {
                $('.header-3').addClass('shown-header').animate({ 'left': 0 }, 800, "easeInOutExpo");
                $('#toggle-header').removeClass('show-header').addClass('hide-header');
                $('#toggle-header').find('i').removeClass('icon-menu2').addClass('icon-cancel3');
            } else if ($('#toggle-header').hasClass('hide-header')) {
                $('.header-3').removeClass('shown-header').animate({ 'left': '-235px' }, 800, "easeInOutExpo");
                $('#toggle-header').removeClass('hide-header').addClass('show-header');
                $('#toggle-header').find('i').removeClass('icon-cancel3').addClass('icon-menu2');
            }
        });
        $('.header-3').bind("clickoutside", function () {
            $('.header-3').removeClass('shown-header').animate({ 'left': '-235px' }, 800, "easeInOutExpo");
            $('#toggle-header').removeClass('hide-header').addClass('show-header');
            $('#toggle-header').find('i').removeClass('icon-cancel3').addClass('icon-menu2');
        });
    });
}


    //---- CSS3 Animations
export var animation = function () {
 $('[data-animation]').each(function () {
        var $animationName = $(this).attr('data-animation'),
            $animationDelay = "delay-" + $(this).attr('data-animation-delay');
        $(this).appear(function () {
            $(this).addClass('animated').addClass($animationName);
            $(this).addClass('animated').addClass($animationDelay);
        });
    });
}