
var $ = require('jquery');
export var sticky_header = function() {
    $(document).ready(function () {
        $('#header').each(function () {
            var homeHeight = $('#home').height() - 1,
                pageHeaderHeight = $('#page-header').height() - 1;
            var headerHeight = $('#header').height();
            $('.header-2').each(function () {
                $(this).after('<div id=\'hidden-header\'></div>');
            });
            $(window).scroll(function () {
                if ($('#home')) {
                    if ($(window).scrollTop() > 100) {
                        $('.header-1').addClass('header-bg');
                        $('.header-2').addClass('sticky');
                        $('.header-2').each(function () {
                            $('#hidden-header').css('height', headerHeight);
                        });
                    } else {
                        $('.header-1').removeClass('header-bg');
                        $('.header-2').removeClass('sticky');
                        $('.header-2').each(function () {
                            $('#hidden-header').css('height', 0);
                        });
                    }
                } else {
                    if ($(window).scrollTop() > pageHeaderHeight) {
                        $('.header-1').addClass('header-bg');
                        $('.header-2').addClass('sticky');
                        $('.header-2').each(function () {
                            $('#hidden-header').css('height', headerHeight);
                        });
                    } else {
                        $('.header-1').removeClass('header-bg');
                        $('.header-2').removeClass('sticky');
                        $('.header-2').each(function () {
                            $('#hidden-header').css('height', 0);
                        });
                    }
                }
            });
        });
    });
}

