import { PageHistoryDetailsComponent } from './components/history/page/page-history-details/page-history-details.component';
import { PropertyResolver } from './components/history/resolves/property.resolve';
import { EventsResolver } from './components/history/resolves/events.resolve';
import { TestimonialResolver } from './components/history/resolves/testimonial.resolve';
import { PropertyHistoryDetailsComponent } from './components/history/property/property-history-details/property-history-details.component';
import { PropertyHistoryViewComponent } from './components/history/property/property-history-view/property-history-view.component';
import { PropertyHistoryListComponent } from './components/history/property/property-history-list/property-history-list.component';

import { EventsHistoryDetailsComponent } from './components/history/events/events-history-details/events-history-details.component';
import { EventsHistoryViewComponent } from './components/history/events/events-history-view/events-history-view.component';
import { EventsHistoryListComponent } from './components/history/events/events-history-list/events-history-list.component';

import { TestimonialDetailsComponent } from './components/history/testimonial/testimonial-history-details/testimonial-history-details.component';
import { TestimonialViewComponent } from './components/history/testimonial/testimonial-history-view/testimonial-history-view.component';
import { TestimonialListComponent } from './components/history/testimonial/testimonial-history-list/testimonial-history-list.component';

import { CareerPageComponent } from './components/pages/career-page/career-page.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { WebsiteLeadListComponent } from './website-leads/website-lead-list/website-lead-list.component';
// tslint:disable-next-line:max-line-length
import { AddUpdateRecommendationComponent } from './components/recommendations/add-update-recommendation/add-update-recommendation.component';
import { JobPostingComponent } from './components/job-posting/job-posting/job-posting.component';
import { JobPostingListComponent } from './components/job-posting/job-posting-list/job-posting-list.component';
import { AddUpdateUserComponent } from './components/user/add-update-user/add-update-user.component';
import { CompanyprofileComponent } from './components/companyprofile/companyprofile.component';

import { Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserListComponent } from './components/user/user-list/user-list.component';
import { AdminPropertyListComponent } from './components/admin-properties/admin-property-list/admin-property-list.component';
import { AdminPropertyComponent } from './components/admin-properties/admin-property/admin-property.component';
import { RecommendationListComponent } from './components/recommendations/recommendations-list/recommendation-list.component';
import { AdminEventsListComponent } from './components/admin-events/admin-events-list/admin-events-list.component';
import { AdminEventsComponent } from './components/admin-events/admin-events/admin-events.component';
import { PropertiesPageComponent } from './components/pages/properties-page/properties-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { IntroPageComponent } from './components/pages/intro-page/intro-page.component';
import { LogosComponent } from './components/logos/logos.component';
import { PropertyHistoryResolver } from './components/history/resolves/property-history.resolve';
import { PageHistoryListComponent } from './components/history/page/page-history-list/page-history-list.component';

export const ADMIN_ROUTES: Routes = [
  {
    path: '',
    component: DashboardComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: {
      animate: true,
    },
  },
  {
    path: 'company-profile',
    component: CompanyprofileComponent,
    data: {
      animate: true,
    },
  },
  {
    path: 'logos',
    component: LogosComponent,
    data: {
      animate: true,
    },
  },
  {
    path: 'user-management',
    children: [
      {
        path: '',
        component: UserListComponent,
      },
      {
        path: 'list',
        component: UserListComponent
      },
      {
        path: 'new',
        component: AddUpdateUserComponent
      },
      {
        path: ':userId',
        component: AddUpdateUserComponent
      }
    ],
    data: {
      animate: true,
    },
  },
  {
    path: 'property',
    children: [
      {
        path: '',
        component: AdminPropertyListComponent
      },
      {
        path: 'list',
        component: AdminPropertyListComponent
      },
      {
        path: 'new',
        component: AdminPropertyComponent
      },
      {
        path: ':propertyId',
        component: AdminPropertyComponent
      }
    ],
    data: {
      animate: true,
    },
  },
  {
    path: 'job-posting',
    children: [
      {
        path: '',
        component: JobPostingListComponent
      },
      {
        path: 'list',
        component: JobPostingListComponent
      },
      {
        path: 'new',
        component: JobPostingComponent
      },
      {
        path: ':jobId',
        component: JobPostingComponent
      }
    ],
    data: {
      animate: true,
    },
  },
  {
    path: 'testimonials',
    children: [
      {
        path: '',
        component: RecommendationListComponent
      },
      {
        path: 'list',
        component: RecommendationListComponent
      },
      {
        path: 'new',
        component: AddUpdateRecommendationComponent
      },
      {
        path: ':recId',
        component: AddUpdateRecommendationComponent
      }
    ],
    data: {
      animate: true,
    },
  },
  {
    path: 'events',
    children: [
      {
        path: '',
        component: AdminEventsListComponent
      },
      {
        path: 'list',
        component: AdminEventsListComponent
      },
      {
        path: 'new',
        component: AdminEventsComponent
      },
      {
        path: ':eventId',
        component: AdminEventsComponent
      }
    ],
    data: {
      animate: true,
    },
  },
  {
    path: 'website-leads',
    component: WebsiteLeadListComponent,
    data: {
      animate: true,
    },
  },
  {
    path: 'pages',
    children: [
      {
        path: '',
        component: HomePageComponent
      },
      {
        path: 'home',
        component: HomePageComponent
      },
      {
        path: 'about',
        component: AboutPageComponent
      },
      {
        path: 'properties',
        component: PropertiesPageComponent
      },
      {
        path: 'contact',
        component: ContactPageComponent
      },
      {
        path: 'career',
        component: CareerPageComponent
      },
      {
        path: 'intro',
        component: IntroPageComponent
      }
    ],
    data: {
      animate: true,
    },
  },
  {
    path: 'history',
    children: [
      {
        path: '',
        component: PropertyHistoryListComponent
      },
      {
        path: 'property',
        component: PropertyHistoryListComponent
      },
      {
        path: 'property/:propertyId',
        component: PropertyHistoryViewComponent
      },
      {
        path: 'property/:propertyId/details/:historyId',
        component: PropertyHistoryDetailsComponent, resolve: { property: PropertyResolver}
      },
      {
        path: 'events',
        component: EventsHistoryListComponent
      },
      {
        path: 'events/:eventId',
        component: EventsHistoryViewComponent
      },
      {
        path: 'events/:eventId/details/:historyId',
        component: EventsHistoryDetailsComponent, resolve: { events: EventsResolver}
      },
      {
        path: 'testimonial',
        component: TestimonialListComponent
      },
      {
        path: 'testimonial/:tmId',
        component: TestimonialViewComponent
      },
      {
        path: 'testimonial/:tmId/details/:historyId',
        component: TestimonialDetailsComponent, resolve: { Testimonials: TestimonialResolver}
      },
      {
        path: 'pages',
        component: PageHistoryListComponent,
      },
      {
        path: 'pages/:page/:historyId/:pageId',
        component: PageHistoryDetailsComponent,
      }
    ],
    data: {
      animate: true,
    },
  },
];

// history: PropertyHistoryResolver
