import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EntryComponent } from './entry.component';


@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [EntryComponent],
})
export class EntryModule { }
