import { ApiService } from './../../../../../services/api.service';
import { HttpService } from './../../../../../services/http.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class TestimonialResolver implements Resolve<any> {

    constructor(
        private http: HttpService,
        private api: ApiService,
        private router: Router) {

    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const self = this;
        const id = route.params['tmId'];
        return self.http.get(self.api.getUrl('RECOMMENDATIONS') + '/' + id).toPromise().then((response) => {
            return response;
        }, (error) => {
            return null;
        });
    }
}

