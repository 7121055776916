import { ListChildRendererComponent } from './../../../../shared/components/list-child';
import { SharedModule } from './../../../../shared/shared.module';
import { JobPostingListComponent } from './job-posting-list/job-posting-list.component';
import { JobPostingComponent } from './job-posting/job-posting.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';



// const routes: Routes = [
//     {
//         path: '',
//         component:
//     },
//     {
//         path: 'list',
//         component:
//     },
//     {
//         path: 'new',
//         component:
//     },
//     {
//         path: ':jobId',
//         component:
//     }
// ];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([ListChildRendererComponent]),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        RouterModule,
        NgbTooltipModule
    ],
    declarations: [JobPostingListComponent, JobPostingComponent ],
    exports: [JobPostingListComponent, JobPostingComponent ],
    entryComponents: []
})
export class JobPostingModule { }
