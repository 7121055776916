import { ListChildRendererComponent } from './../../../../shared/components/list-child';
import { SharedModule } from './../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RecommendationListComponent } from './recommendations-list/recommendation-list.component';
import { AddUpdateRecommendationComponent } from './add-update-recommendation/add-update-recommendation.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([ListChildRendererComponent]),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        RouterModule,
    ],
    declarations: [AddUpdateRecommendationComponent, RecommendationListComponent],
    exports: [AddUpdateRecommendationComponent, RecommendationListComponent],
    entryComponents: []
})
export class AdminRecommendationsPostingModule { }
