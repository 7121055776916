import { ListChildRendererComponent } from './../../../../../shared/components/list-child';
import { ApiService } from './../../../../../services/api.service';
import { HttpService } from './../../../../../services/http.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {

  userForm: FormGroup;
  @ViewChild('agGrid') agGrid: ElementRef;
  gridOptions: any;
  filterPresent = false;
  params: any;
  totalRowData: number;
  filter: any;
  private gridApi;
  private gridColumnApi;
  private frameworkComponents;
  private context;
  columnDefs;
  searchValue: any;
  private components;
  private paginationPageSize = 20;
  dataSource: any;

  //
  roles: Array<any> = [];
  roleName: any = '';
  isLoading = false;
  records: Array<any> = [];
  totalSize = 0;
  curPage = 1;
  size = 20;
  subscriptions: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private httpService: HttpService,
    private api: ApiService
  ) {


    this.columnDefs = [
      {
        headerName: 'SI. No',
        width: 150,
        valueGetter: 'node.id',
        cellRenderer: 'rowIdRenderer'
      },
      {
        headerName: 'Username',
        field: 'username',
        minWidth: 200
      },
      {
        headerName: 'Name',
        field: 'name',
        minWidth: 300
      },
      {
        headerName: 'Manage',
        cellRenderer: 'userListUpdate',
        suppressMenu: true,
        suppressFilter: true,
      }
    ];
    this.context = { componentParent: this };
    this.frameworkComponents = {
      userListUpdate: ListChildRendererComponent
    };
    this.components = {
      rowIdRenderer: function (params) {
        return Number(params.rowIndex) + 1;
      },
    };
  }

  public ngOnInit() {
    const self = this;
    if (this.columnDefs) {
      this.configureGridSettings();
    }
    self.buildDocumentForm();
  }

  buildDocumentForm() {
    const self = this;
    self.userForm = self.formBuilder.group({
      'name': ['', []],
      'userName': ['', []],
    });

  }


  configureGridSettings(): void {
    this.gridOptions = {
      frameworkComponents: this.frameworkComponents,
      enableFilter: false,
      components: this.components,
      context: this.context,
      enableSorting: false,
      enableColResize: true,
      rowDeselection: false,
      rowHeight: 45,
      pagination: true,
      columnDefs: this.columnDefs,
      headerHeight: 45,
    };
  }

  onReady(params: any): any {
    const self = this;
    self.gridApi = params.api;
    self.params = params;
    self.gridColumnApi = params.columnApi;
    self.httpService.get(self.api.getUrl('USER'))
      .subscribe(data => {
        params.api.setRowData(data['users']);
      });
  }
  // onInfiniteScroll(infiniteParams) {
  //   const self = this;

  //   self.totalRowData = 0;
  //   this.dataSource = {
  //     rowCount: null,
  //     getRows: (params) => {
  //       const pageNo = params.endRow / self.paginationPageSize;
  //       self.isLoading = true;
  //       if (pageNo === 1) {
  //         self.totalRowData = 0;
  //       }
  //       const listParams = {
  //         'count': self.paginationPageSize,
  //         'page': pageNo
  //       };
  //       if (this.filter) {
  //         listParams['filter'] = this.filter;
  //       }
  //       self.subscriptions['userList'] = self.httpService.get(self.api.getUrl('USER'), listParams)
  //         .subscribe((result) => {
  //           if (result && result['success']) {
  //             let finalEnd = -1;
  //             self.isLoading = false;
  //             self.totalSize = result['count'];
  //             self.totalRowData = self.totalRowData + result['users'].length;
  //             if (result['users'].length < this.paginationPageSize) {
  //               finalEnd = self.totalRowData;
  //             }
  //             params.successCallback(result['users'], finalEnd);
  //             if (pageNo === 1 && result['users'].length < 1) {
  //               this.gridApi.showNoRowsOverlay();
  //             } else {
  //               if (this.totalRowData !== 0 && this.gridApi) {
  //                 this.gridApi.hideOverlay();
  //               }
  //             }
  //           }
  //         }, (err) => {
  //           self.isLoading = false;
  //           params.successCallback([], self.totalRowData);
  //           if (self.totalRowData === 0) {
  //             this.gridOptions.api.showNoRowsOverlay();
  //           }
  //         });
  //     },
  //     destroy: () => {
  //       // this.hasRoles = false;
  //     }
  //   };
  //   this.gridApi.setDatasource(this.dataSource);
  // }
  ngOnDestroy() {
    const self = this;
    Object.keys(self.subscriptions).forEach(e => {
      self.subscriptions[e].unsubscribe();
    });
  }
  edit(data) {
    const self = this;
    self._router.navigateByUrl('admin/user-management/' + data['_id']);
  }
  delete(data) {
    const self = this;
    Swal.fire({
      title: 'Warning!',
      text: 'User will be Deleted.',
      type: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    }).then(isConfirm => {
      if (isConfirm && isConfirm['value']) {
        self.httpService.delete(self.api.getUrl('USER') + '/' + data['_id'])
          .subscribe((_res) => {
            this.params.filterModel = {};
            this.onReady(this.params);
          }, err => {
            Swal.fire({
              title: 'Error!',
              text: 'Unable to Delete user',
              type: 'error',
              confirmButtonText: 'Ok'
            });
          });
      }
    });
  }
  navigate() {
    const self = this;
    self._router.navigateByUrl('admin/user-management/new');
  }


  onSearchChange() {
    const self = this;
    this.gridApi.setQuickFilter(self.searchValue);
  }
}
