import { AboutPageComponent } from './about-page/about-page.component';
import { SharedModule } from './../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HomePageComponent } from './home-page/home-page.component';
import { PropertiesPageComponent } from './properties-page/properties-page.component';
import { ContactPageComponent } from './contact-page/contact-page.component';
import { CareerPageComponent } from './career-page/career-page.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IntroPageComponent } from './intro-page/intro-page.component';




@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        RouterModule,
        ImageCropperModule
    ],
    declarations: [HomePageComponent, AboutPageComponent, PropertiesPageComponent,
        ContactPageComponent, CareerPageComponent, IntroPageComponent],
    exports: [HomePageComponent, AboutPageComponent, PropertiesPageComponent,
        ContactPageComponent, CareerPageComponent, IntroPageComponent],
    entryComponents: []
})
export class PagesModule { }
