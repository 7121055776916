import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VideoGuard implements CanActivate {
    constructor(private api: ApiService, private http: HttpService, private auth: AuthService, private _router: Router) { }

    canActivate(): Observable<boolean> | Promise<any> | boolean {
        const self = this;
        return self.http.get(this.api.getUrl('COMPANY')).toPromise().then((data) => {
            if (data && data['company'] && data['company']['showIntroVideo']) {
                return true;
            } else {
                this._router.navigate(['/home']);
                return false;
            }
        }, () => {
           return true;
        });
    }
}
