import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

    tabs = [{ 'label': 'English', 'value': 'english' }, { 'label': 'Arabic', 'value': 'arabic' }];

    @Input() selectedTab = 'english';

    @Output() change = new EventEmitter();

    constructor() { }

    ngOnInit(): void { }

    changeLanguage(tab) {
        const self = this;
        self.selectedTab = tab['value'];
        self.change.emit(tab['value']);
    }
}
