import { FooterModule } from './../footer/footer.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { AboutUsComponent } from './components/about-us/about-us.component';



@NgModule({
    imports: [CommonModule, RouterModule, CarouselModule, FooterModule],
    declarations: [AboutUsComponent],
    exports: [AboutUsComponent]
})
export class AboutUsModule { }
