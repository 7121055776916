import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProtectedComponent } from './protected.component';

import { SharedModule } from './../../shared/shared.module';
import { NavComponent } from './components/nav/nav.component';

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule],
    declarations: [ProtectedComponent, NavComponent],
})
export class ProtectedModule {}
