import { ImageCropperComponent } from './image-cropper.component';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';



@NgModule({
    imports: [CommonModule, ImageCropperModule,
        FormsModule, ReactiveFormsModule],
    declarations: [ImageCropperComponent],
    exports: [ImageCropperComponent],
})
export class ImageCropModule { }
