import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    data = [];
    userName: any;
    constructor() { }

    set(key: string, value: any) {
        this.data[key] = value;
    }

    get(key: string): any {
        return !!key ? this.data[key] : this.data;
    }
    setUser(name) {
        this.userName = name;
    }
    getUserName() {
        return this.userName;
    }
    getPropertiesArabic() {
        return {
            'count': 10,
            'banner': 'assets/images/properties.webp',
            'description': `ذلك دأبوا أجزاء الشهيرة تم, ٣٠ كما وقرى مساعدة الصفحات. ان وتم لهيمنة الرئيسية, لعملة لإعادة ذلك في, يعبأ لعملة إيو ما? كل بلديهما الأولية أما, بحث تكبّد الأول أساسي قد? حين هو ماذا واتّجه بقيادة!`,
            'properties': [
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real.jpg',

                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real2.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real3.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real4.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real.jpg',

                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real2.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real3.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real4.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real.jpg',

                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real2.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real3.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 لودجفيل رود',
                    'location': 'غولدن فالي، من 55427',
                    'image': 'assets/images/real4.jpg'
                },
            ]
        };
    }

    getProperties() {
        return {
            'count': 10,
            'banner': 'assets/images/properties.webp',
            'description': `Loremm Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br>the
                                  industry's standard dummy text ever since the 1500`,
            'properties': [
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real.jpg',

                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real2.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real3.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'residential',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real4.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real5.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'industrial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real6.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real7.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real8.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real9.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real10.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'commercial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real11.jpg'
                },
                {
                    'name': 'Zaleej Properties',
                    'type': 'industrial',
                    'pinCode': '12332',
                    'address': '3398 Lodgeville Road',
                    'location': 'Golden Valley, MN 55427',
                    'image': 'assets/images/real12.jpg'
                }
            ]
        };
    }

    commonData() {
        return {
            'logo': {
                'light': 'assets/images/light-logo.png',
                'dark': 'assets/images/dark-logo.png'
            },
            'menu': [
                { 'order': 1, 'name': 'Home', 'path': '/home' },
                { 'order': 2, 'name': 'Properties', 'path': '/properties' },
                { 'order': 3, 'name': 'About Us', 'path': '/about' },
                { 'order': 6, 'name': 'Testimonials', 'path': '/testimonial' },
                { 'order': 4, 'name': 'Contact', 'path': '/contact' },
                { 'order': 5, 'name': 'Careers', 'path': '/careers' },
            ],
            'contactInfo': {
                'companyName': 'Zaleej Real Estate Company(KSCC)',
                'companyAddress': 'P.O Box 17919, Khaldiya 72460,Kuwait',
                'phone': '+965 22997888',
                'lattitude': '29.3257614',
                'longitude': '47.9545859'
            },
            'socialMedia': {
                'facebook': '',
                'twitter': '',
                'linkedIn': '',
                'instagram': '',
                'youtube': ''
            },
            'copyright': '2017 COPYRIGHT ZALEEJ REAL ESTATE COMPANY(KSCC). ALL RIGHTS RESERVED'
        };
    }
    commonDataArabic() {
        return {
            'logo': {
                'light': 'assets/images/light-logo.png',
                'dark': 'assets/images/dark-logo.png'
            },
            'menu': [
                { 'order': 1, 'name': 'الصفحة الرئيسية', 'path': '/home' },
                { 'order': 2, 'name': 'العقارات', 'path': '/properties' },
                { 'order': 3, 'name': 'معلومات عنا', 'path': '/about' },
                { 'order': 4, 'name': 'اتصل بنا', 'path': '/contact' },
                { 'order': 5, 'name': 'وظائف', 'path': '/careers' },
                { 'order': 6, 'name': 'شهادة', 'path': '/testimonial' },
            ],
            'contactInfo': {
                'companyName': 'شركة زليج العقارية (ش.م.ك',
                'companyAddress': '17919،خالدية ٧٢٤٦٠, كويت',
                'phone': '+965 22997888',
                'lattitude': '29.3257614',
                'longitude': '47.9545859'
            },
            'socialMedia': {
                'facebook': '',
                'twitter': '',
                'linkedIn': '',
                'instagram': '',
                'youtube': ''
            },
            'copyright': '2017 جميع الحقوق محفوظة لشركة زليج العقارية (ش.م.ك.م). كل الحقوق محفوظة'
        };
    }

    getHomeDataArabic() {
        return {};

        // return {
        //     'banner': 'assets/images/hero.webp',
        //     'propertyDescription': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500`,
        //     'properties': [
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 لودجفيل رود',
        //             'location': 'غولدن فالي، من 55427',
        //             'image': 'assets/images/real.jpg',

        //         },
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 لودجفيل رود',
        //             'location': 'غولدن فالي، من 55427',
        //             'image': 'assets/images/real2.jpg'
        //         },
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 لودجفيل رود',
        //             'location': 'غولدن فالي، من 55427',
        //             'image': 'assets/images/real3.jpg'
        //         },
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 لودجفيل رود',
        //             'location': 'غولدن فالي، من 55427',
        //             'image': 'assets/images/real4.jpg'
        //         },
        //     ],
        //     'recommendations': [
        //         {
        //             // tslint:disable-next-line:max-line-length
        //             'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
        //             'name': 'بيكا',
        //             'designation': 'مساعد مدير',
        //             'image': 'assets/images/prof1.jpg'
        //         },
        //         {
        //             // tslint:disable-next-line:max-line-length
        //             'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
        //             'name': 'مايكل',
        //             'designation': 'مساعد مدير',
        //             'image': 'assets/images/prof2.jpg'
        //         },
        //         {
        //             // tslint:disable-next-line:max-line-length
        //             'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
        //             'name': 'مايكل',
        //             'designation': 'مساعد مدير',
        //             'image': 'assets/images/prof3.jpg'
        //         },
        //         {
        //             // tslint:disable-next-line:max-line-length
        //             'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
        //             'name': 'جوديث',
        //             'designation': 'مساعد مدير',
        //             'image': 'assets/images/prof3.jpg'
        //         },
        //         {
        //             // tslint:disable-next-line:max-line-length
        //             'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
        //             'name': 'مايكل',
        //             'designation': 'مساعد مدير',
        //             'image': 'assets/images/prof1.jpg'
        //         },
        //     ]

        // };

    }

    getHomeData() {
        return {};
        
        // return {
        //     'banner': 'assets/images/hero.webp',
        //     'propertyDescription': `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been<br> the
        //                                  industry's standard dummy text ever since the 1500s`,
        //     'properties': [
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 Lodgeville Road',
        //             'location': 'Golden Valley, MN 55427',
        //             'image': 'assets/images/real.jpg',

        //         },
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 Lodgeville Road',
        //             'location': 'Golden Valley, MN 55427',
        //             'image': 'assets/images/real2.jpg'
        //         },
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 Lodgeville Road',
        //             'location': 'Golden Valley, MN 55427',
        //             'image': 'assets/images/real3.jpg'
        //         },
        //         {
        //             'name': 'Zaleej Properties',
        //             'type': 'commercial',
        //             'pinCode': '12332',
        //             'address': '3398 Lodgeville Road',
        //             'location': 'Golden Valley, MN 55427',
        //             'image': 'assets/images/real4.jpg'
        //         },
        //     ],
        //     'recommendations': [
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Becca ne',
        //             'designation': 'Assistant Manager',
        //             'image': 'assets/images/prof1.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Michael',
        //             'designation': 'CEO of sales',
        //             'image': 'assets/images/prof2.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'John',
        //             'designation': 'Marketing Manager',
        //             'image': 'assets/images/prof3.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Judith',
        //             'designation': 'Tech lead',
        //             'image': 'assets/images/prof3.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Becca',
        //             'designation': 'Associate consultant',
        //             'image': 'assets/images/prof1.jpg'
        //         },
        //     ]

        // };

    }

    getAboutDataArabic() {
        return {
            'banner': 'assets/images/about.jpg',
            // tslint:disable-next-line:max-line-length
            'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ `,
            'slideImages': [
                'assets/images/about1.jpg', 'assets/images/about2.jpg', 'assets/images/about3.jpg',
                'assets/images/about1.jpg', 'assets/images/about2.jpg', 'assets/images/about3.jpg'
            ],
            'slideDescription': `
                                وقد حققت نموا متسقا وسمعة راسخة في أسواق العقارات التي نعمل فيها. متتلك زليج وتدير محفظة متنوعة من العق
                                ارات التجارية والصناعية والسكنية في الكويت و
                                ونحن نواصل نشاطنا في البحث عن فرص جديدة للنمو والاستثمار والتنم
                                ية استنادا إلى معايير واضحة وعمليات إدارة متينة.`,
            'recommendations': [
                {
                    // tslint:disable-next-line:max-line-length
                    'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    'name': 'بيكا',
                    'designation': 'مساعد مدير',
                    'image': 'assets/images/prof1.jpg'
                },
                {
                    // tslint:disable-next-line:max-line-length
                    'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    'name': 'مايكل',
                    'designation': 'مساعد مدير',
                    'image': 'assets/images/prof2.jpg'
                },
                {
                    // tslint:disable-next-line:max-line-length
                    'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    'name': 'مايكل',
                    'designation': 'مساعد مدير',
                    'image': 'assets/images/prof3.jpg'
                },
                {
                    // tslint:disable-next-line:max-line-length
                    'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    'name': 'جوديث',
                    'designation': 'مساعد مدير',
                    'image': 'assets/images/prof3.jpg'
                },
                {
                    // tslint:disable-next-line:max-line-length
                    'description': `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    'name': 'مايكل',
                    'designation': 'مساعد مدير',
                    'image': 'assets/images/prof1.jpg'
                },
            ]

        };
    }
    getAboutData() {
        return {};
        // return {
        //     'banner': 'assets/images/about.jpg',
        //     'description': `Loremm Ipsum about is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br>the
        //                           industry's standard dummy text ever since the 1500`,
        //     'slideImages': [
        //         'assets/images/about1.jpg', 'assets/images/about2.jpg', 'assets/images/about3.jpg',
        //         'assets/images/about1.jpg', 'assets/images/about2.jpg', 'assets/images/about3.jpg'
        //     ],
        //     'slideDescription': `Loremm Ipsum about is simply dummy text of the printing and typesetting industry.
        //                              Lorem Ipsum has been <br>the
        //                           industry's standard dummy text ever since the 1500,industry's standard dummy text ever since the 1500,
        //                           industry's standard dummy text ever since the 1500,
        //                           industry's standard dummy text ever since the 1500`,
        //     'recommendations': [
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Becca ne',
        //             'designation': 'Assistant Manager',
        //             'image': 'assets/images/prof1.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Michael',
        //             'designation': 'CEO of sales',
        //             'image': 'assets/images/prof2.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'John',
        //             'designation': 'Marketing Manager',
        //             'image': 'assets/images/prof3.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Judith',
        //             'designation': 'Tech lead',
        //             'image': 'assets/images/prof3.jpg'
        //         },
        //         {
        //             'description': `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
        //             'name': 'Becca',
        //             'designation': 'Associate consultant',
        //             'image': 'assets/images/prof1.jpg'
        //         },
        //     ]

        // };
    }

    getContactData() {
        return {
            'banner': 'assets/images/hero2.jpg'
        };
    }
    getJobs() {
        return {
            'banner': 'assets/images/careers.jpg',
            'jobs': [{
                title: 'SENIOR ONLINE TRADING MANAGER',
                postedOn: '1/10/2018',
                type: 'Full time',
                salary: {
                    'currency': '$',
                    'min': '0',
                    'max': '100',
                    'per': 'hour'
                },
                tags: 'BSEE,BSCS,BSCE',
                experience: '3years',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore',
                requirements: ['Lorem ipsum dolor sit amet, sed do eiusmod tempor', 'Consectetur adipisicing elit, sed do eiusmod tempor'],
                jobLocation: 'Kuwait'
            },
            {
                title: 'SENIOR ONLINE TRADING MANAGER',
                postedOn: '1/10/2018',
                type: 'Full time',
                salary: {
                    'currency': '$',
                    'min': '0',
                    'max': '100',
                    'per': 'hour'
                },
                tags: 'BSEE,BSCS,BSCE',
                experience: '3years',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore',
                requirements: ['Lorem ipsum dolor sit amet, sed do eiusmod tempor', 'Consectetur adipisicing elit, sed do eiusmod tempor'],
                jobLocation: 'Kuwait'
            },
            {
                title: 'SENIOR ONLINE TRADING MANAGER',
                postedOn: '1/10/2018',
                type: 'Full time',
                salary: {
                    'currency': '$',
                    'min': '0',
                    'max': '100',
                    'per': 'hour'
                },
                tags: 'BSEE,BSCS,BSCE',
                experience: '3years',
                description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore',
                requirements: ['Lorem ipsum dolor sit amet, sed do eiusmod tempor', 'Consectetur adipisicing elit, sed do eiusmod tempor'],
                jobLocation: 'Kuwait'
            }
            ]
        };

    }
    getJobsArabic() {
        return {
            'banner': 'assets/images/careers.jpg',
            'jobs': [{
                title: 'سينيور أونلين ترادينغ ماناجر',
                postedOn: '15 نوفمبر 2017',
                type: 'وقت كامل',
                salary: {
                    'currency': '$',
                    'min': '0',
                    'max': '100',
                    'per': 'ساعة'
                },
                tags: 'بسي,بسس,بس',
                experience: '3 سنوات الخبرة',
                description: `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان
                 أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                requirements: ['أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان',
                 'هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s'],
                jobLocation: 'Kuwait'
            },
                {
                    title: 'سينيور أونلين ترادينغ ماناجر',
                    postedOn: '15 نوفمبر 2017',
                    type: 'وقت كامل',
                    salary: {
                        'currency': '$',
                        'min': '0',
                        'max': '100',
                        'per': 'ساعة'
                    },
                    tags: 'بسي,بسس,بس',
                    experience: '3 سنوات الخبرة',
                    description: `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان
                 أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    requirements: ['أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان',
                        'هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s'],
                    jobLocation: 'Kuwait'
                },
                {
                    title: 'سينيور أونلين ترادينغ ماناجر',
                    postedOn: '15 نوفمبر 2017',
                    type: 'وقت كامل',
                    salary: {
                        'currency': '$',
                        'min': '0',
                        'max': '100',
                        'per': 'ساعة'
                    },
                    tags: 'بسي,بسس,بس',
                    experience: '3 سنوات الخبرة',
                    description: `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان
                 أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    requirements: ['أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان',
                        'هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s'],
                    jobLocation: 'Kuwait'
                },
                {
                    title: 'سينيور أونلين ترادينغ ماناجر',
                    postedOn: '15 نوفمبر 2017',
                    type: 'وقت كامل',
                    salary: {
                        'currency': '$',
                        'min': '0',
                        'max': '100',
                        'per': 'ساعة'
                    },
                    tags: 'بسي,بسس,بس',
                    experience: '3 سنوات الخبرة',
                    description: `أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان
                 أبجد هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s`,
                    requirements: ['أبجد هوز هو مجرد دمية النص من الطباعة والتنضيد الصناعة. وكان',
                        'هوز نص الدمية القياسية في هذه الصناعة من أي وقت مضى منذ 1500s'],
                    jobLocation: 'Kuwait'
                },
            ]
        };

    }
}
