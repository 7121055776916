import { ContactModule } from './../contact/contact.module';
import { FooterModule } from './../footer/footer.module';
import { AboutUsModule } from './../about us/about-us.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { CarouselModule } from 'ngx-owl-carousel-o';


import { HomeComponent } from './components/home/home.component';


@NgModule({
    imports: [CommonModule, RouterModule,
        FormsModule, ReactiveFormsModule,
         CarouselModule, AboutUsModule, FooterModule, ContactModule],
    declarations: [HomeComponent],
})
export class HomeModule { }
