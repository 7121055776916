import { Injectable } from '@angular/core';

const base_url =  'https://zaleej.com/api'; // 'http://localhost:3000/api';
const image_base_url = 'https://zaleej.com/api';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor() { }

    URLS = {
        USER: base_url + '/v1/cms/users',
        LOGIN: base_url + '/v1/cms/users/login',
        COMPANY: base_url + '/v1/cms/company',
        JOBS: base_url + '/v1/cms/jobs',
        PROPERTIES: base_url + '/v1/cms/properties',
        EVENTS: base_url + '/v1/cms/events',
        LEADS: base_url + '/v1/cms/leads',
        RECOMMENDATIONS: base_url + '/v1/cms/recommendations',
        DASHBOARD: base_url + '/v1/cms/dashboard',


        // CMS Pages
        CMSINTROPAGE: base_url + '/v1/cms/pages/intro',
        CMSHOMEPAGE: base_url + '/v1/cms/pages/home',
        CMSPROPERTYPAGE: base_url + '/v1/cms/pages/property',
        CMSABOUTPAGE: base_url + '/v1/cms/pages/about',
        CMSCONTACTPAGE: base_url + '/v1/cms/pages/contact',
        CMSCAREERPAGE: base_url + '/v1/cms/pages/career',
        CMSLOGOPAGE: base_url + '/v1/cms/pages/logo',

        // WEB Pages
        CONTACTFORM: base_url + '/v1/web/contact-form',
        WEBINTROPAGE: base_url + '/v1/web/intro',
        WEBCOMMON: base_url + '/v1/web/common',
        WEBHOMEPAGE: base_url + '/v1/web/home',
        WEBPROPERTYPAGE: base_url + '/v1/web/property',
        WEBPROPERTYPAGEFILTER: base_url + '/v1/web/property/filter',
        WEBABOUTPAGE: base_url + '/v1/web/about',
        WEBCONTACTPAGE: base_url + '/v1/web/contact',
        WEBCAREERPAGE: base_url + '/v1/web/career',

        // Uploads
        SINGLEIMAGE: base_url + '/v1/cms/uploads/image/single',
        MULTIPLEIMAGES: base_url + '/v1/cms/uploads/image/multiple',
        SINGLEVIDEO: base_url + '/v1/cms/uploads/video/single',

        // HISTORY
        PROPERTIES_HISTORY: base_url + '/v1/cms/properties/history',
        PROPERTIES_RESTORE: base_url + '/v1/cms/properties/restore',
        EVENTS_HISTORY: base_url + '/v1/cms/events/history',
        EVENTS_RESTORE: base_url + '/v1/cms/events/restore',
        TESTIMONIAL_HISTORY: base_url + '/v1/cms/recommendations/history',
        TESTIMONIAL_RESTORE: base_url + '/v1/cms/recommendations/restore',
        PAGES_HISTORY: base_url + '/v1/cms/pages/history',
        PAGES_RESTORE: base_url + '/v1/cms/pages/restore',
        PAGE_URL: base_url + '/v1/cms/pages',
        // get image
        IMAGE: image_base_url,

    };

    getUrl(key: string): string {
        return this.URLS[key];
    }

}
