import { AuthService } from './auth.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import { Injectable, Inject } from '@angular/core';
import Swal from 'sweetalert2';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(public authService: AuthService, private router: Router,
        @Inject(LOCAL_STORAGE) private localStorage: any, ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authService.getToken()) {
            request = this.addToken(request, this.authService.getToken());
        }
        return next.handle(request)
            .pipe(
                catchError((err, caught: Observable<HttpEvent<any>>) => {
                    if (err instanceof HttpErrorResponse && err.status === 401 ||
                        err instanceof HttpErrorResponse && err.status === 403 ||
                        err instanceof HttpErrorResponse && err.status === 440) {
                        Swal.fire({
                            title: 'Error!',
                            text: err.error.message,
                            type: 'error',
                            confirmButtonText: 'Ok'
                        }).then(ok => {
                            self.localStorage.removeItem('Authorization');
                            self.localStorage.removeItem('User');
                            this.router.navigate(['login']);
                        });
                        return of(err as any);
                    }
                    throw err;
                })
            );
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'Authorization': `Bearer ${token}`
            }
        });
    }
}
