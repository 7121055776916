import { ApiService } from './../../../../services/api.service';
import { HttpService } from './../../../../services/http.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit  , OnDestroy {
  dashboardData;
  subscriptions = {};

  constructor(
    private httpService: HttpService,
    private api: ApiService,
  ) { }

  ngOnInit() {
    const self = this;
    self.getData();
  }

  getData() {
    const self = this;
    self.subscriptions['dashboarData'] = self.httpService.get(self.api.getUrl('DASHBOARD'))
      .subscribe(res => {
        if (res && res['success']) {
          self.dashboardData = res['counts'];
        }
      });

  }
  ngOnDestroy() {
    const self = this;
    Object.keys(self.subscriptions).forEach(e => {
      self.subscriptions[e].unsubscribe();
    });
  }

}
