import { ImageCropperComponent } from './../../../../../shared/components/image-croppper/image-cropper.component';
import { FormValidator } from './../../../../../services/form-validator.service';
import { CommonService } from './../../../../../services/common.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as _ from 'lodash';


@Component({
    selector: 'app-admin-property',
    templateUrl: './admin-property.component.html',
    styleUrls: ['./admin-property.component.scss']
})
export class AdminPropertyComponent implements OnInit, OnDestroy {
    propertyForm: FormGroup;
    subscriptions: any = {};
    propertyId: any;
    selectedPropertyName;
    selectedAddress;
    selectedCity;
    selectedCountry;
    payload: any;
    mainImageUploadUrl: 'SINGLEIMAGE';
    previewUrl: any;
    mainImage: any;
    galleryImages = [];
    isFileUploaded = false;
    isGalleryUploaded = false;
    formData: any;
    fileUploadSuccess = false;
    previousGalleryImages = [];
    types = ['COMMERCIAL', 'INDUSTRIAL', 'RESIDENTIAL'];

    constructor(private formBuilder: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private _cs: CommonService,
        private modalService: NgbModal,
    ) {

    }

    ngOnInit() {
        const self = this;
        self.selectedPropertyName = self.selectedAddress = self.selectedCity = self.selectedCountry = 'english';
        self.buildForm();
        self.subscriptions['params'] = self._route.params.subscribe((params: Params) => {
            if (params && params['propertyId']) {
                self.propertyId = params['propertyId'];
                self.getPropertyById(self.propertyId);
            }
        });
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'mainImage') {
                self.mainImage = documents.path;
                self.galleryImages.push(documents);
            }
        });
        self.subscriptions['allImages'] = self._cs.allImages$.subscribe(documents => {
            documents = documents.filter(_item => _item['type'] === 'propertyGallery');
            self.isGalleryUploaded = false;
             self.galleryImages = _.uniqBy(self.galleryImages.concat(documents), documents.originalName);
            setTimeout(() => {
                self.fileUploadSuccess = false;
            }, 2000);
        });
        self.subscriptions['image'] = self._cs.fileUploaded.subscribe(image => {
            if (image && image['type'] === 'mainImage') {
                self.mainImage = image['image'];
                self.galleryImages.push(image);
            }
        });
    }


    buildForm() {
        const self = this;
        self.propertyForm = self.formBuilder.group({
            'type': ['', Validators.required],
            'propertyName': ['', Validators.required],
            'propertyNameArabic': ['', Validators.required],
            'address': ['', Validators.required],
            'addressArabic': ['', Validators.required],
            'city': ['', Validators.required],
            'cityArabic': ['', Validators.required],
            'country': ['', Validators.required],
            'countryArabic': ['', Validators.required],
            'size': ['', Validators.required],
            'rooms': ['', Validators.required],
            'active': ['', Validators.required],
            'featured': [''],
            // 'mainImage': ['', Validators.required],
            // 'gallery': ['', Validators.required],
        });
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.propertyForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }

    setFormData(res) {
        const self = this;
        const formData = res['property'];
        self.previousGalleryImages = [];
        if (formData) {
            if (formData['main_image']) {
                self.mainImage = formData['main_image'];
                self.previousGalleryImages = _.uniq(self.previousGalleryImages.concat(formData['gallery'], formData['main_image']));
                self.isFileUploaded = true;
            }
            self.propertyForm.patchValue({
                'type': formData['type'],
                'active': formData['active'],
                'propertyName': formData['name_en'],
                'propertyNameArabic': formData['name_ar'],
                'address': formData['address_en'],
                'addressArabic': formData['address_ar'],
                'city': formData['location_en'],
                'cityArabic': formData['location_ar'],
                'country': formData['country_en'],
                'countryArabic': formData['country_ar'],
                'size': formData['size'],
                'rooms': formData['rooms'],
                'featured': formData['featured'],
               // 'mainImage': formData['main_image'],
               // 'gallery': formData['gallery']
            });
        }
    }
    reset() {
        const self = this;
        self.propertyForm.reset();
    }
    generatePayload(formData) {
        const self = this;
        let galleryImages = [];
         galleryImages = _.map(self.galleryImages, 'path');
        if (self.previousGalleryImages) {
            galleryImages = galleryImages.concat(self.previousGalleryImages);
        }
        const payload = {
            'type': formData['type'],
            'active' : formData['active'],
            'name_en' : formData['propertyName'],
            'name_ar' : formData['propertyNameArabic'],
            'address_en' : formData['address'],
            'address_ar' : formData['addressArabic'],
            'location_en' : formData['city'],
            'location_ar' : formData['cityArabic'],
            'country_en' : formData['country'],
            'country_ar' : formData['countryArabic'],
            'size' : formData['size'],
            'rooms': formData['rooms'],
            'featured': formData['featured'],
            'gallery': galleryImages
        };

        if (self.propertyId) {
            payload['_id'] = self.propertyId;
        }
        if (self.mainImage) {
            payload['main_image'] = self.mainImage;
        }

        return payload;
    }

    getPropertyById(id) {
        const self = this;
        self.subscriptions['getPropertyById'] =  self.httpService.get(self.api.getUrl('PROPERTIES') + '/' + id).subscribe((_res) => {
            self.formData = _res['property'];
            self.setFormData(_res);
        });
    }

    save(fromDelete?) {
        const self = this;
        const formData = self.generatePayload(self.propertyForm.getRawValue());
        if (self.propertyForm.valid && self.mainImage) {
            if (self.propertyId) {
                self.updateProperty(formData, fromDelete);
            } else {
                self.subscriptions['createProperty'] = self.httpService.put(self.api.getUrl('PROPERTIES'), formData)
                .subscribe(_res => {
                    if (_res && _res['success']) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'property created successfully!',
                            type: 'success',
                            confirmButtonText: 'Ok'
                        }).then((isConfirm) => {
                            if (isConfirm) {
                                self._router.navigateByUrl('admin/property/list');
                            }
                        });
                    }
                }, err => {
                        Swal.fire({
                            title: 'Error!',
                            text: 'Property creation failed!',
                            type: 'error',
                            confirmButtonText: 'Ok'
                        });
                });
            }
        } else {
            if (!self.mainImage) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Please Upload Main Image!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            }
            FormValidator.validateForm(self.propertyForm);
        }
    }

    updateProperty(formData, fromDelete?) {
        const self = this;
        self.subscriptions['updateProperty'] = self.httpService.post(self.api.getUrl('PROPERTIES'), formData)
        .subscribe(_res => {
            if (_res && _res['success'] && !fromDelete) {
            Swal.fire({
                title: 'Success!',
                text: 'property updated successfully!',
                type: 'success',
                confirmButtonText: 'Ok'
            }).then((isConfirm) => {
                if (isConfirm) {
                    self._router.navigateByUrl('admin/property/list');
                }
            });
        }
        }, err => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Property Updation failed!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
        });
    }
    selectTabPropertyName(tab) {
        const self = this;
        self.selectedPropertyName = tab;

    }
    selectAddressTab(tab) {
        const self = this;
        self.selectedAddress = tab;
    }
    selectCityTab(tab) {
        const self = this;
        self.selectedCity = tab;
    }
    selectCountryTab(tab) {
        const self = this;
        self.selectedCountry = tab;
    }

    onImageUpload(event: any): void {
        this.isFileUploaded = event.isFileUploaded;
        this.fileUploadSuccess = true;
        this._cs.addDocument(event.response.file, 'mainImage');
    }
    onGalleryImageUpload(event) {
        const self = this;
        self.isGalleryUploaded = false;
        self.fileUploadSuccess = true;
        self._cs.addDocument(event.response.file, 'propertyGallery');
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    onDeleteMainImage(event) {
        const self = this;
        this.isFileUploaded = event.isFileUploaded;
        self.mainImage = null;
    }
    ngOnDestroy() {
        const self = this;
        self.galleryImages = [];
        self.mainImage = [];
        self.previousGalleryImages = [];
        self._cs.documents = [];
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }
    deleteOldImages(i, image) {
        const self = this;
        const payload = {
            path : image
        };
        Swal.fire({
            title: 'Warning!',
            text: 'Image Will be deleted!',
            type: 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
        }).then(confirm => {
            if (confirm && confirm['value']) {
                self.previousGalleryImages.splice(i, 1);
                // self.save(true);
                // self.subscriptions['deleteImage'] = self.httpService.delete(self.api.getUrl('SINGLEIMAGE'), payload)
                //     .subscribe(_res => {
                //         if (_res && _res['success']) {
                //             self.previousGalleryImages.splice(i, 1);
                //             self.save(true);
                //         }
                //     }, err => {
                //         Swal.fire({
                //             title: 'Error!',
                //             text: 'Image Deletion failed!',
                //             type: 'error',
                //             confirmButtonText: 'Ok'
                //         });
                //     });
            }
        });
    }
    deleteNewImages(i, image) {
        const self = this;
        const payload = {
            path: image
        };
        Swal.fire({
            title: 'Warning!',
            text: 'Image Will be deleted!',
            type: 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
        }).then(confirm => {
            if (confirm && confirm['value']) {
                self.galleryImages.splice(i, 1);
                // self.subscriptions['deleteImage'] = self.httpService.delete(self.api.getUrl('SINGLEIMAGE'), payload)
                //     .subscribe(_res => {
                //         if (_res && _res['success']) {
                //             self.galleryImages.splice(i, 1);
                //         }
                //     }, err => {
                //         Swal.fire({
                //             title: 'Error!',
                //             text: 'Image Deletion failed!',
                //             type: 'error',
                //             confirmButtonText: 'Ok'
                //         });
                //     });
            }
        });
    }

    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        // modalRef.componentInstance.aspectRatio = 16 / 9;
        modalRef.componentInstance.resizeToWidth = 650;
    }
}

