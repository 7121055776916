import { ImageCropperComponent } from './../../../../../shared/components/image-croppper/image-cropper.component';
import { CommonService } from './../../../../../services/common.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
    selector: 'app-properties-page',
    templateUrl: './properties-page.component.html',
    styleUrls: ['./properties-page.component.scss']
})
export class PropertiesPageComponent implements OnInit, OnDestroy {

    pageForm: FormGroup;
    videosUploadUrl = 'VIDEO_URL';
    slideUploadUrl = 'SLIDE_URL';
    bannerUploadUrl = 'BANNER_URL';
    subscriptions = {};
    selectedBannerTitle: any;
    selectedBannerSubTitle: any;
    selectedDescription: any;
    bannerUploaded = false;
    bannerImage: any;
    formData: any;
    propertyPageData: any;
    propertyPageId: any;

    constructor(private fb: FormBuilder, private apiService: ApiService,
        private _router: Router,
        private _cs: CommonService,
        private modalService: NgbModal,
        private httpService: HttpService) { }

    ngOnInit(): void {
        const self = this;
        self.selectedBannerTitle = self.selectedBannerSubTitle = self.selectedDescription = 'english';
        self.buildForm();
        self.getPropertyPageData();
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'bannerImage') {
                self.bannerImage = documents.path;
            }
        });
    }

    buildForm() {
        const self = this;
        self.pageForm = self.fb.group({
            'bannerTitle': [''],
            'description': [''],
            'bannerTitleArabic': [''],
            'descriptionArabic': ['']
        });
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.pageForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }
    reset() {
        const self = this;
        self.pageForm.reset();
    }

    save() {
        const self = this;
        const payload = self.generatePayload(self.pageForm.getRawValue());
        // tslint:disable-next-line:max-line-length
        self.subscriptions['propertyHomeData'] = self.httpService.post(self.apiService.getUrl('CMSPROPERTYPAGE'), payload)
            .subscribe(_res => {
                if (_res && _res['success']) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Property Page data created successfully!',
                        type: 'success',
                        confirmButtonText: 'Ok'
                    }).then((isConfirm) => {
                        if (isConfirm) {
                            self._router.navigateByUrl('admin/pages/properties');
                        }
                    });
                }
            }, err => {
                Swal.fire({
                    title: 'Error!',
                    text: 'Property Page data creation failed!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    }
    selectTabBannerTitle(tab) {
        const self = this;
        self.selectedBannerTitle = tab;
    }
    selectTabBannerSubTitle(tab) {
        const self = this;
        self.selectedBannerSubTitle = tab;
    }
    selectTabDescription(tab) {
        const self = this;
        self.selectedDescription = tab;
    }
    onBannerUpload(event: any): void {
        this.bannerUploaded = event.isFileUploaded;
        this._cs.addDocument(event.response.file, 'banner');
    }

    generatePayload(formData) {
        const self = this;
        const payload = {
            banner_image: self.bannerImage,
            banner_caption_en: formData['bannerTitle'],
            banner_caption_ar: formData['bannerTitleArabic'],
            banner_description_en: formData['description'],
            banner_description_ar: formData['descriptionArabic'],
        };
        if (self.propertyPageId) {
            payload['_id'] = self.propertyPageId;
        }
        return payload;
    }
    onDeleteBannerImage(event) {
        const self = this;
        this.bannerUploaded = event.isFileUploaded;
        self.bannerImage = null;
    }


    getPropertyPageData() {
        const self = this;
        self.subscriptions['getPropertyPageData'] =  self.httpService.get(self.apiService.getUrl('CMSPROPERTYPAGE')).subscribe((res) => {
            if (res && res['success'] && res['page']) {
                self.propertyPageData = res['page'];
                self.propertyPageId = res.page['_id'],
                    self.setFormData(res['page']);
            }
        });
    }

    setFormData(formData) {
        const self = this;
        if (formData) {
            self.bannerImage = formData['banner_image'];
            self.bannerUploaded = true;
            self.pageForm.patchValue({
                bannerTitle: formData['banner_caption_en'],
                bannerTitleArabic: formData['banner_caption_ar'],
                description: formData['banner_description_en'],
                descriptionArabic: formData['banner_description_ar'],
            });
        }
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.aspectRatio = 5 / 4;
        modalRef.componentInstance.resizeToWidth = 1920;
        modalRef.componentInstance.imageQuality = 40;
      //  modalRef.componentInstance.maintainAspectRatio = true;
        // modalRef.componentInstance.resizeToHeight = 1080;
        modalRef.componentInstance.format = 'jpeg';
    }
}
