import { FormValidator } from './../../../../../services/form-validator.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import Swal from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'app-add-update-user',
  templateUrl: './add-update-user.component.html',
  styleUrls: ['./add-update-user.component.scss']
})
export class AddUpdateUserComponent implements OnInit {
  userForm: FormGroup;
  subscriptions: any = {};
  userId: any;
  dropdownSettings = {
    singleSelection: false,
    idField: '_id',
    textField: 'name_en',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  formData: any;
  titles = ['Mr.', 'Mrs.', 'Ms.'];
  selectedProperties: any;
  properties: any;
  constructor(private formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private httpService: HttpService,
    private api: ApiService
  ) {

  }

  ngOnInit() {
    const self = this;
    self.buildForm();
    // self.getProperties();
    self.subscriptions['params'] = self._route.params.subscribe((params: Params) => {
      if (params && params['userId']) {
        self.userId = params['userId'];
        self.getUserById(self.userId);
      }
    });
  }


  buildForm() {
    const self = this;
    self.userForm = self.formBuilder.group({
      'prefix': ['', Validators.required],
      'firstName': ['', Validators.required],
      'lastName': [],
      'email': ['', Validators.required],
      'userName': ['', Validators.required],
      'password': ['', Validators.required],
      'confirmPassword': ['', Validators.required],
      'isAdmin': [false, ''],
      'properties': [''],
      'allPropertiesAccess': [false, '']
    }, { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') });
  }
  getfield(controlName: string) {
    const controls = controlName.split('.');
    let currentCtrl = this.userForm.controls[controls[0]];
    controls.forEach((ctrl, i) => {
      if (i !== 0) {
        currentCtrl = currentCtrl['controls'][ctrl];
      }
    });
    return currentCtrl;
  }

  setFormData(formData) {
    const self = this;
    self.selectedProperties = [];
    if (formData) {
      if (formData['properties'] && formData['properties'].length) {
        self.selectedProperties = formData['properties'].map(_item => {
          const found = _.find(self.properties, ['_id', _item]);
          if (found) {
            _item = found;
            _item['selected'] = true;
          }
          return _item;
        });
      }
      self.userForm.patchValue({
        'prefix': formData['title'],
        'active': formData['active'],
        'firstName': formData['firstname'],
        'lastName': formData['lastname'],
        'email': formData['email'],
        'userName': formData['username'],
        'isAdmin': formData['isAdmin'],
        'allPropertiesAccess': formData['allPropertiesAccess'],
        'properties': self.selectedProperties
      });
    }
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordConfirmationInput.touched) {
        if (passwordInput.value !== passwordConfirmationInput.value) {
          return passwordConfirmationInput.setErrors({ notEquivalent: true });

        }
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
  reset() {
    const self = this;
    self.userForm.reset();
  }
  generatePayload(formData) {
    const self = this;
    let properties = [];
    if ((self.selectedProperties && self.selectedProperties.length) && !formData['allPropertiesAccess']) {
      properties = self.selectedProperties.map(_item => _item['_id']);
    }
    const payload = {
      'title': formData['prefix'],
      'firstname': formData['firstName'],
      'lastname': formData['lastName'],
      'username': formData['userName'],
      'email': formData['email'],
      'password': formData['password'],
      'isAdmin': formData['isAdmin'],
      'allPropertiesAccess': formData['allPropertiesAccess'],
      'properties': !formData['allPropertiesAccess'] ? properties : []
    };
    if (self.userId) {
      payload['_id'] = self.userId;
    }
    return payload;
  }

  getUserById(id: string) {
    const self = this;
    self.httpService.get(self.api.getUrl('USER') + '/' + id).subscribe((_res) => {
      if (_res && _res['success']) {
        self.formData = _res['user'];
        self.setFormData(_res['user']);
      }
    });
  }

  save() {
    const self = this;
    const formData = self.userForm.getRawValue();
    const payload = self.generatePayload(formData);
    if (self.userId) {
      const formControls = ['password', 'confirmPassword'];
      formControls.forEach((_item) => {
        self.userForm.controls[_item].setValidators([]);
        self.userForm.controls[_item].updateValueAndValidity({ onlySelf: false, emitEvent: false });
      });
    }
    if (self.userForm.valid) {
      if (self.userId) {
        self.updateUser(payload, self.userId);
      } else {
        self.subscriptions['createUser'] = self.httpService.put(self.api.getUrl('USER'), payload).subscribe(_res => {
          if (_res && _res['success']) {
            Swal.fire({
              title: 'Success!',
              text: 'User Created Successfully.',
              type: 'success',
              confirmButtonText: 'Ok'
            }).then(isConfirm => {
              if (isConfirm) {
                self._router.navigateByUrl('admin/user-management/list');
              }
            });
          }
        }, err => {
          Swal.fire({
            title: 'Error!',
            text: err.error.message ? err.error.message : 'Unable to Create User',
            type: 'error',
            confirmButtonText: 'Ok'
          });
        });
      }
    } else {
      FormValidator.validateForm(self.userForm);
    }
  }

  updateUser(formData, id) {
    const self = this;
    self.subscriptions['updateUser'] = self.httpService.post(self.api.getUrl('USER'), formData).subscribe(_res => {
      if (_res && _res['success']) {
        Swal.fire({
          title: 'Success!',
          text: 'User Updated successfully!',
          type: 'success',
          confirmButtonText: 'Ok'
        }).then((isConfirm) => {
          if (isConfirm) {
            self._router.navigateByUrl('admin/user-management/list');
          }
        });
      }
    }, err => {
      Swal.fire({
        title: 'Error!',
        text: err.error.message ? err.error.message : 'Unable to Update User',
        type: 'error',
        confirmButtonText: 'Ok'
      });
    });
  }

  getProperties() {
    const self = this;
    self.httpService.get(self.api.getUrl('PROPERTIES')).subscribe(data => {
      if (data && data['success'] && data['properties']) {
        self.properties = data['properties'];
      }
    });
  }
  onSelectAll(eve) {
  }

  onItemSelect(eve) {
  }
}
