import { ImageCropperComponent } from './../../../../../shared/components/image-croppper/image-cropper.component';
import { CommonService } from './../../../../../services/common.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';



@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    pageForm: FormGroup;
    videosUploadUrl = 'VIDEO_URL';
    slideUploadUrl = 'SLIDE_URL';
    bannerUploadUrl = 'BANNER_URL';
    subscriptions = {};
    selectedBannerCaption: any;
    selectedLeftPanelCaption: any;
    isBannerUploaded = false;
    bannerImage: any;
    fileUploadSuccess = false;
    isGalleryUploaded = false;
    formData: any;
    galleryImages = [];
    contactPageId: any;
    selectedLeftPanelDescription: string;
    selectedRightPanelDescription: any;
    selectedRightPanelCaption: any;
    contactPageData: any;

    constructor(private fb: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private _cs: CommonService,
        private modalService: NgbModal, ) { }

    ngOnInit(): void {
        const self = this;
        self.selectedBannerCaption = self.selectedLeftPanelCaption =
            self.selectedRightPanelDescription = self.selectedRightPanelCaption =
         self.selectedLeftPanelDescription =  'english';
        self.buildForm();
        self.getPageData();
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'bannerImage') {
                self.bannerImage = documents.path;
            }
        });

    }

    buildForm() {
        const self = this;
        self.pageForm = self.fb.group({
            banner_caption_en: ['', Validators.required],
            banner_caption_ar: ['', Validators.required],
            left_pane_caption_en: ['', Validators.required],
            left_pane_caption_ar: ['', Validators.required],
            left_pane_description_en: ['', Validators.required],
            left_pane_description_ar: ['', Validators.required],
            right_pane_caption_en: ['', Validators.required],
            right_pane_caption_ar: ['', Validators.required],
            right_pane_description_en: ['', Validators.required],
            right_pane_description_ar: ['', Validators.required]
        });
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.pageForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }


    onDeleteFile() {
        const self = this;
    }
    reset() {
        const self = this;
        self.pageForm.reset();
    }

    save() {
        const self = this;
        const payload = self.generatePayload(self.pageForm.getRawValue());
        if (self.pageForm.valid) {
            self.subscriptions['contactPage'] = self.httpService.post(self.api.getUrl('CMSCONTACTPAGE'), payload)
                .subscribe(_res => {
                    if (_res && _res['success']) {
                        Swal.fire({
                            title: 'Success!',
                            text: 'Contact Page data created successfully!',
                            type: 'success',
                            confirmButtonText: 'Ok'
                        }).then((isConfirm) => {
                            if (isConfirm) {
                                self._router.navigateByUrl('admin/pages/contact');
                            }
                        });
                    }
                }, err => {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Contact Page data creation failed!',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
        }
    }


    generatePayload(formData) {
        const self = this;

        const payload = {
            banner_image: self.bannerImage,
            banner_caption_en: formData['banner_caption_en'],
            banner_caption_ar: formData['banner_caption_ar'],
            left_pane_caption_en: formData['left_pane_caption_en'],
            left_pane_caption_ar: formData['left_pane_caption_ar'],
            left_pane_description_en: formData['left_pane_description_en'],
            left_pane_description_ar: formData['left_pane_description_ar'],
            right_pane_caption_en: formData['right_pane_caption_en'],
            right_pane_caption_ar: formData['right_pane_caption_ar'],
            right_pane_description_en: formData['right_pane_description_en'],
            right_pane_description_ar: formData['right_pane_description_ar']
        };
        if (self.contactPageId) {
            payload['_id'] = self.contactPageId;
        }
        if (self.bannerImage) {
            payload['banner_image'] = self.bannerImage;
        }

        return payload;
    }
    onDeleteBannerImage(event) {
        const self = this;
        this.isBannerUploaded = event.isFileUploaded;
        self.bannerImage = null;
    }
    onImageUpload(event: any): void {
        this.isBannerUploaded = event.isFileUploaded;
        // this.fileUploadSuccess = true;
        this._cs.addDocument(event.response.file, 'banner');
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    selectTabBannerCaption(tab) {
        const self = this;
        self.selectedBannerCaption = tab;
    }
    selectTabLeftPanelCaption(tab) {
        const self = this;
        self.selectedLeftPanelCaption = tab;
    }
    selectTabLeftPanelDescription(tab) {
        const self = this;
        self.selectedLeftPanelDescription = tab;
    }
    selectTabRightPanelDescription(tab) {
        const self = this;
        self.selectedRightPanelDescription = tab;
    }
    selectTabRightPanelCaption(tab) {
        const self = this;
        self.selectedRightPanelCaption = tab;
    }
    getPageData() {
        const self = this;
        self.httpService.get(self.api.getUrl('CMSCONTACTPAGE')).subscribe(res => {
            if (res && res['success'] && res['page']) {
                self.contactPageData = res['page'];
                self.contactPageId = res.page['_id'],
                    self.setFormData(res['page']);
            }
        });
    }
    setFormData(formData) {
        const self = this;
        if (formData) {
            self.bannerImage = formData['banner_image'];
            self.isBannerUploaded = true;
            self.pageForm.patchValue({
                banner_caption_en: formData['banner_caption_en'],
                banner_caption_ar: formData['banner_caption_ar'],
                left_pane_caption_en: formData['left_pane_caption_en'],
                left_pane_caption_ar: formData['left_pane_caption_ar'],
                left_pane_description_en: formData['left_pane_description_en'],
                left_pane_description_ar: formData['left_pane_description_ar'],
                right_pane_caption_en: formData['right_pane_caption_en'],
                right_pane_caption_ar: formData['right_pane_caption_ar'],
                right_pane_description_en: formData['right_pane_description_en'],
                right_pane_description_ar: formData['right_pane_description_ar']
            });
        }
    }
    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        modalRef.componentInstance.aspectRatio = 5 / 4;
        modalRef.componentInstance.resizeToWidth = 1920;
        modalRef.componentInstance.imageQuality = 40;
        //  modalRef.componentInstance.maintainAspectRatio = true;
        // modalRef.componentInstance.resizeToHeight = 1080;
        modalRef.componentInstance.format = 'jpeg';
    }
}
