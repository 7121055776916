import { ImageCropperComponent } from './../../../../../shared/components/image-croppper/image-cropper.component';
import { CommonService } from './../../../../../services/common.service';
import { HttpService } from './../../../../../services/http.service';
import { ApiService } from './../../../../../services/api.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as _ from 'lodash';




@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit, OnDestroy {

    pageForm: FormGroup;
    tagsForm: FormGroup;

    tagsFormAr: FormGroup;
    @ViewChild('tags') tags: ElementRef;
    @ViewChild('tagsAr') tagsAr: ElementRef;
    videosUploadUrl = 'VIDEO_URL';
    slideUploadUrl = 'SLIDE_URL';
    bannerUploadUrl = 'BANNER_URL';
    subscriptions = {};
    selectedBannerTitle: any;
    selectedBannerSubTitle: any;
    selectedDescription: any;
    selectedAboutCaption: any;
    selectedAboutDescription: any;
    isBannerUploaded = false;
    bannerImage: any;
    fileUploadSuccess = false;
    isGalleryUploaded = false;
    formData: any;
    galleryImages = [];
    previousGalleryImages = [];
    aboutPageId: any;
    aboutPageData: any;

    constructor(private fb: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private _cs: CommonService,
        private modalService: NgbModal, ) { }

    ngOnInit(): void {
        const self = this;
        self.buildForm();

        self.getAboutPageData().then(res => {
            self.aboutPageData = res;
            self.aboutPageId = res['_id'];
            self.setFormData(res);
        });
        self.selectedBannerTitle =
            self.selectedBannerSubTitle =
            self.selectedAboutCaption =
            self.selectedAboutDescription =
            self.selectedDescription = 'english';
        self.subscriptions['documents'] = self._cs.documents$.subscribe(documents => {
            if (documents && documents['type'] === 'bannerImage') {
                self.bannerImage = documents.path;
            }
        });
        self.subscriptions['allImages'] = self._cs.allImages$.subscribe(documents => {
            documents = documents.filter(_item => _item['type'] === 'aboutGallery');
            if (documents && documents.length) {
                self.isGalleryUploaded = false;
                self.galleryImages = _.uniqBy(self.galleryImages.concat(documents), documents.originalName);
            }
            setTimeout(() => {
                self.fileUploadSuccess = false;
            }, 2000);
        });
        self.buildTagsArForm();
        self.buildTagsForm();
    }

    buildForm() {
        const self = this;
        self.pageForm = self.fb.group({
            banner_caption_en: ['', Validators.required],
            banner_caption_ar: ['', Validators.required],
            banner_description_en: ['', Validators.required],
            banner_description_ar: ['', Validators.required],
            about_caption_en: ['', Validators.required],
            about_caption_ar: ['', Validators.required],
            about_description_en: ['', Validators.required],
            about_description_ar: ['', Validators.required],
        });
    }
    getfield(controlName: string) {
        const controls = controlName.split('.');
        let currentCtrl = this.pageForm.controls[controls[0]];
        controls.forEach((ctrl, i) => {
            if (i !== 0) {
                currentCtrl = currentCtrl['controls'][ctrl];
            }
        });
        return currentCtrl;
    }


    onDeleteFile() {
        const self = this;
    }
    reset() {
        const self = this;
        self.pageForm.reset();
    }

    save(fromDelete?) {
        const self = this;
        const payload = self.generatePayload(self.pageForm.getRawValue());
        self.subscriptions['aboutPageData'] = self.httpService.post(self.api.getUrl('CMSABOUTPAGE'), payload)
            .subscribe(_res => {
                if (_res && _res['success'] && !fromDelete) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'About Page data created successfully!',
                        type: 'success',
                        confirmButtonText: 'Ok'
                    }).then((isConfirm) => {
                        if (isConfirm) {
                            self._router.navigateByUrl('admin/pages/about');
                        }
                    });
                }
            }, err => {
                Swal.fire({
                    title: 'Error!',
                    text: 'About Page data creation failed!',
                    type: 'error',
                    confirmButtonText: 'Ok'
                });
            });
    }

    selectTabBannerTitle(tab) {
        const self = this;
        self.selectedBannerTitle = tab;
    }
    selectTabBannerSubTitle(tab) {
        const self = this;
        self.selectedBannerSubTitle = tab;
    }
    selectTabDescription(tab) {
        const self = this;
        self.selectedDescription = tab;
    }
    selectTabAboutDescription(tab) {
        const self = this;
        self.selectedAboutDescription = tab;
    }
    selectTabAboutCaption(tab) {
        const self = this;
        self.selectedAboutCaption = tab;
    }
    generatePayload(formData) {
        const self = this;
        let galleryImages = [];
        galleryImages = _.map(self.galleryImages, 'path');
        if (self.previousGalleryImages) {
            galleryImages = galleryImages.concat(self.previousGalleryImages);
        }
        const tags = self.tagsForm.getRawValue()['tag'];
        const tagsAr = self.tagsFormAr.getRawValue()['tag'];
        const tagsArray = [];
        const tagsArrayAr = [];
        tags.forEach((item, index) => {
            if (item['name'] !== null || item['name'] !== undefined || item['name'] !== '') {
                const obj = {
                    order: index + 1,
                    requirement: item['name'],
                };
                tagsArray.push(obj);
            }
        });
        tagsAr.forEach((item, index) => {
            if (item['name'] !== null || item['name'] !== undefined || item['name'] !== '') {
                const obj = {
                    order: index + 1,
                    requirement: item['name'],
                };
                tagsArrayAr.push(obj);
            }
        });
        const payload = {
            banner_caption_en: formData['banner_caption_en'],
            banner_caption_ar: formData['banner_caption_ar'],
            banner_description_en: formData['banner_description_en'],
            banner_description_ar: formData['banner_description_ar'],
            about_slide_images: galleryImages,
            about_caption_en: formData['about_caption_en'],
            about_caption_ar: formData['about_caption_ar'],
            about_description_en: formData['about_description_en'],
            about_description_ar: formData['about_description_ar'],
            list_en: tagsArray,
            list_ar: tagsArrayAr

        };
        if (self.aboutPageId) {
            payload['_id'] = self.aboutPageId;
        }
        if (self.bannerImage) {
            payload['banner_image'] = self.bannerImage;
        }

        return payload;
    }
    onDeleteBannerImage(event) {
        const self = this;
        this.isBannerUploaded = event.isFileUploaded;
        self.bannerImage = null;
    }
    onImageUpload(event: any): void {
        this.isBannerUploaded = event.isFileUploaded;
        this.fileUploadSuccess = true;
        this._cs.addDocument(event.response.file, 'banner');
    }
    onGalleryImageUpload(event) {
        const self = this;
        self.isGalleryUploaded = false;
        self.fileUploadSuccess = true;
        this._cs.addDocument(event.response.file, 'aboutGallery');
    }
    openPreview(content) {
        this.modalService.open(content, { centered: true, windowClass: 'preview-modal' });
    }
    onDeletebannerImage(event) {
        const self = this;
        this.isBannerUploaded = event.isFileUploaded;
        self.bannerImage = null;
    }
    getAboutPageData() {
        const self = this;
        return new Promise((resolve, reject) => {
            self.httpService.get(self.api.getUrl('CMSABOUTPAGE')).subscribe(res => {
                if (res && res['success'] && res['page']) {
                    resolve(res['page']);
                }
            }, err => {
                resolve();
            });
        });
    }
    setFormData(formData) {
        const self = this;
        self.previousGalleryImages = [];
        if (formData) {
            self.bannerImage = formData['banner_image'];
            self.previousGalleryImages = self.previousGalleryImages.concat(formData['about_slide_images']);
            self.isBannerUploaded = true;
            self.pageForm.patchValue({
                banner_caption_en: formData['banner_caption_en'],
                banner_caption_ar: formData['banner_caption_ar'],
                banner_description_en: formData['banner_description_en'],
                banner_description_ar: formData['banner_description_ar'],
                about_caption_en: formData['about_caption_en'],
                about_caption_ar: formData['about_caption_ar'],
                about_description_en: formData['about_description_en'],
                about_description_ar: formData['about_description_ar'],
            });
            if (self.aboutPageId) {
                if (formData && formData['list_en'] && formData['list_en'].length) {
                    formData['list_en'].forEach(item => {
                        self.onAddTag(item);
                    });
                } else {
                    self.onAddTag();
                }
                if (formData && formData['list_ar'] && formData['list_ar'].length) {
                    formData['list_ar'].forEach(item => {
                        self.onAddArTag(item);
                    });
                } else {
                    self.onAddArTag();
                }
            }
        }
    }
    ngOnDestroy() {
        const self = this;
        self.galleryImages = [];
        self._cs.documents = [];
        self.previousGalleryImages = [];
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }
    deleteAboutGallery() {
        const self = this;
        if (self.previousGalleryImages && self.galleryImages) {
            Swal.fire({
                title: 'Warning!',
                text: 'All about page Images will be deleted.',
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Ok',
            }).then(isConfirm => {
                if (isConfirm && isConfirm['value']) {
                    self.previousGalleryImages = [];
                    self.galleryImages = [];
                }
            });
        }
    }
    open(type) {
        const self = this;
        const modalRef = this.modalService.open(ImageCropperComponent, { size: 'lg' });
        modalRef.componentInstance.type = type;
        if (type === 'bannerImage') {
            modalRef.componentInstance.aspectRatio = 5 / 4;
            modalRef.componentInstance.resizeToWidth = 1920;
            modalRef.componentInstance.imageQuality = 40;
            //  modalRef.componentInstance.maintainAspectRatio = true;
            // modalRef.componentInstance.resizeToHeight = 1080;
            modalRef.componentInstance.format = 'jpeg';

        } else if (type === 'aboutGallery') {
            modalRef.componentInstance.aspectRatio = 16 / 9;
            modalRef.componentInstance.resizeToWidth = 555;
            modalRef.componentInstance.maintainAspectRatio = true;
            modalRef.componentInstance.format = 'jpeg';
        }
    }

    deleteOldImages(i, image) {
        const self = this;
        const payload = {
            path: image
        };
        Swal.fire({
            title: 'Warning!',
            text: 'Image Will be deleted!',
            type: 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
        }).then(confirm => {
            if (confirm && confirm['value']) {
                self.previousGalleryImages.splice(i, 1);
                self.save(true);
                // self.subscriptions['deleteImage'] = self.httpService.delete(self.api.getUrl('SINGLEIMAGE'), payload)
                //     .subscribe(_res => {
                //         if (_res && _res['success']) {
                //             self.previousGalleryImages.splice(i, 1);
                //             self.save(true);
                //         }
                //     }, err => {
                //         Swal.fire({
                //             title: 'Error!',
                //             text: 'Image Deletion failed!',
                //             type: 'error',
                //             confirmButtonText: 'Ok'
                //         });
                //     });
            }
        });
    }
    deleteNewImages(i, image) {
        const self = this;
        const payload = {
            path: image
        };
        Swal.fire({
            title: 'Warning!',
            text: 'Image Will be deleted!',
            type: 'warning',
            confirmButtonText: 'Ok',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
        }).then(confirm => {
            if (confirm && confirm['value']) {
                self.galleryImages.splice(i, 1);
                // self.subscriptions['deleteImage'] = self.httpService.delete(self.api.getUrl('SINGLEIMAGE'), payload)
                //     .subscribe(_res => {
                //         if (_res && _res['success']) {
                //             self.galleryImages.splice(i, 1);
                //         }
                //     }, err => {
                //         Swal.fire({
                //             title: 'Error!',
                //             text: 'Image Deletion failed!',
                //             type: 'error',
                //             confirmButtonText: 'Ok'
                //         });
                //     });
            }
        });
    }
    buildTagsForm() {
        const self = this;
        self.tagsForm = self.fb.group({
            'tag': self.fb.array([])
        });
        // if (!self.aboutPageId) {
        //     self.onAddTag();
        // }
    }
    buildTagsArForm() {
        const self = this;
        self.tagsFormAr = self.fb.group({
            'tag': self.fb.array([])
        });
        // if (!self.aboutPageId) {
        //     self.onAddArTag();
        // }
    }
    onAddTag(tag?) {
        const self = this;
        const tagArrayControls = <FormArray>self.tagsForm.get('tag');
        const newFormControlName: AbstractControl = new FormControl(tag ? tag.requirement : '');
        const newFormGroup = new FormGroup({});
        newFormGroup.addControl('name', newFormControlName);
        tagArrayControls.push(newFormGroup);
    }
    onAddArTag(tag?) {
        const self = this;
        const tagArrayControls = <FormArray>self.tagsFormAr.get('tag');
        const newFormControlName: AbstractControl = new FormControl(tag ? tag.requirement : '');
        const newFormGroup = new FormGroup({});
        newFormGroup.addControl('name', newFormControlName);
        tagArrayControls.push(newFormGroup);
    }
    removeTag(index) {
        const self = this;
        const tagArrayControls = <FormArray>self.tagsForm.get('tag');
        if (tagArrayControls.length === 1) {
            self.tagsForm.reset();
            self.tags.nativeElement.reset();
        } else {
            tagArrayControls.removeAt(index);
        }

    }
    removeArTag(index) {
        const self = this;
        const tagArrayControls = <FormArray>self.tagsFormAr.get('tag');
        if (tagArrayControls.length === 1) {
            self.tagsFormAr.reset();
            self.tagsAr.nativeElement.reset();
        } else {
            tagArrayControls.removeAt(index);
        }

    }
}
