import { WebsiteLeadChildRendererComponent } from './../../../../../../shared/components/website-lead-child';
import { ApiService } from './../../../../../../services/api.service';
import { HttpService } from './../../../../../../services/http.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-events-history-list',
    templateUrl: './events-history-list.component.html',
    styleUrls: ['./events-history-list.component.scss']
})
export class EventsHistoryListComponent implements OnInit, OnDestroy {
    apiEndPoint: string;
    gridOptions: any;
    pagination: any;
    totalRowData: any;
    params: any;
    searchValue: any;
    dataSource: any;
    private gridColumnApi;

    @ViewChild('inputSearch') inputSearch: ElementRef;

    pageNo = 1;
    filter: any;
    stopCall = true;
    totalSize: number;
    private gridApi;
    private frameworkComponents;
    private context;
    columnDefs;
    private components;
    subscriptions: any = {};

    constructor(
        private _router: Router,
        private httpService: HttpService,
        private api: ApiService,
        private modalService: NgbModal
    ) {
        this.columnDefs = [
          {
            headerName: 'SI. No',
            width: 150,
            valueGetter: 'node.id',
            cellRenderer: 'rowIdRenderer'
          },
          {
            headerName: 'Event Name',
            field: 'event_name',
            minWidth: 300
          },
          {
            headerName: 'Event Venue',
            field: 'venue',
            minWidth: 200
          },
          {
            headerName: 'Event Country',
            field: 'country',
            minWidth: 200
          },
          {
            headerName: 'Date',
            field: 'date',
            minWidth: 200
          },
          {
            headerName: 'Manage',
            cellRenderer: 'leadsListUpdate',
            suppressMenu: true,
            suppressFilter: true,
          }
        ];
        this.context = { componentParent: this };
        this.frameworkComponents = {
            leadsListUpdate: WebsiteLeadChildRendererComponent
        };
        this.components = {
            rowIdRenderer: function (params) {
                return Number(params.rowIndex) + 1;
            },
        };
    }

    public ngOnInit() {
        const self = this;
        if (this.columnDefs) {
            this.configureGridSettings();
        }
    }



    configureGridSettings(): void {
        this.gridOptions = {
            frameworkComponents: this.frameworkComponents,
            enableFilter: false,
            components: this.components,
            context: this.context,
            enableSorting: false,
            enableColResize: true,
            rowDeselection: false,
            rowHeight: 45,
            pagination: true,
            columnDefs: this.columnDefs,
            headerHeight: 45,
        };
    }

    onReady(params: any): any {
        const self = this;
        self.gridApi = params.api;
        self.params = params;
        self.gridColumnApi = params.columnApi;
        self.httpService.get(self.api.getUrl('EVENTS_HISTORY'))
            .subscribe(data => {
                if (data && data['success']) {
                    params.api.setRowData(data['history']);
                }
            });
    }
    ngOnDestroy() {
        const self = this;
        Object.keys(self.subscriptions).forEach(e => {
            self.subscriptions[e].unsubscribe();
        });
    }

    view(data) {
        const self = this;
        self._router.navigate(['admin/history/events/' + data['event_id']]);
    }

    onSearchChange(input) {
        const self = this;
        this.gridApi.setQuickFilter(self.searchValue);
    }
}
