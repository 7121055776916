import { CommonService } from './../../../services/common.service';
import { ApiService } from './../../../services/api.service';
import { HttpService } from './../../../services/http.service';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { WINDOW } from '@ng-toolkit/universal';
import Swal from 'sweetalert2';



@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
    imageChangedEvent = '';
    croppedImage = '';
    @Input() uploadType = 'image';
    @Input() API_PATH = 'SINGLEIMAGE';
    type: any;
    imageFile: File;
    resizeToWidth = 512;
    aspectRatio = 4 / 3 ;
    roundCropper = false;
    onlyScaleDown = false;
    imageQuality = 92;
    format = 'png';
    btnClicked = false;
    maintainAspectRatio = false;
    constructor(public activeModal: NgbActiveModal,
        @Inject(WINDOW) private window: Window,
        private http: HttpService, private api: ApiService,
        private _cs: CommonService
        ) { }

    ngOnInit(): void {
        const self = this;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        const self = this;
        self.croppedImage = event.base64;
        self.getImage(self.croppedImage);
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }
    save() {
        const self = this;
        if (self.croppedImage) {
            self._cs.fileUploaded.next({
                image: self.croppedImage,
                type: self.type
            });
            self.activeModal.dismiss();
        }
    }

    getImage(url) {
        const self = this;
        const date = new Date().valueOf();
        let text = '';
        const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
        }
        const imageName = date + '.' + text + '.jpeg';
        const imageBlob = self.dataURItoBlob(self.croppedImage);
        self.imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    }

    dataURItoBlob(dataURI) {
        const self = this;
         let blob;
        const byteString = self.window.atob(dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
            const arrayBuffer = new ArrayBuffer(byteString.length);
            const int8Array = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteString.length; i++) {
                int8Array[i] = byteString.charCodeAt(i);
            }
             blob = new Blob([int8Array], { type: 'image/jpeg' });
             return blob;
    }

    uploadFile() {
        const self = this;
        if (self.btnClicked) {
            return;
        }
        self.btnClicked = true;
        if (self.API_PATH && self.imageFile) {
            const formData: FormData = new FormData();
            formData.append(self.uploadType, self.imageFile);
            this.http.postFormData(this.api.getUrl(self.API_PATH), formData).subscribe(
                data => {
                    if (data && data['success']) {
                        this._cs.addDocument(data['file'], self.type);
                        self.btnClicked = false;
                        self.activeModal.dismiss();
                    }
                },
                err => {
                    self.btnClicked = false;
                    Swal.fire({
                        title: 'Error!',
                        text: 'Something went wrong, please try again!',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            );
        }
    }
}
