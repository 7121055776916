import { SharedModule } from './../../../../shared/shared.module';
import { ListChildRendererComponent } from './../../../../shared/components/list-child';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AddUpdateUserComponent } from './add-update-user/add-update-user.component';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UserListComponent } from './user-list/user-list.component';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule.withComponents([ListChildRendererComponent]),
        // NgMultiSelectDropDownModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
    ],
    declarations: [AddUpdateUserComponent, UserListComponent],
    exports: [AddUpdateUserComponent, UserListComponent]
})
export class UserModule { }
